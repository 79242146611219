import React, { useState } from 'react'; 
import '../css/NavBar.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { Navbar, Nav, NavItem, NavDropdown } from 'react-bootstrap';
import { Link } from "react-router-dom";
import NavbarLogo from '../img/NavbarLogo.jpg';


const NavBar = () => {
    const [expanded, setExpanded] = useState(false);
    const [show1, setShow1] = useState(false);

    const showDropdown1 = e => {
        setShow1(!show1);
    };
    const hideDropdown1 = e => {
        setShow1(false);
    };

    return (
        <header className="navbar-container">
        <Navbar expand='md' fixed='top' bg='navbar' expanded={expanded}>
            <Navbar.Brand as={Link} to="/" className="order-md-0 order-0" onClick={() => setExpanded(false)}>
                    {/* <a onclick="scroll(0,0)"> */}
                        <img src={NavbarLogo} alt="Empowered Conversation Logo" className='logo'/>
                    {/* </a> */}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)}/>
            <Navbar.Collapse id="basic-navbar-nav" className="order-md-1 order-1">
                <Nav className="mr-auto">
                    <NavItem className="donate-container">
                        <Nav.Link as={Link} to="/home" bsPrefix="donate" onClick={() => setExpanded(false)} >Home</Nav.Link>
                    </NavItem>
                    <NavDropdown title="About" id="dropdown" className="dropdown" 
                        show={show1}
                        onMouseEnter={showDropdown1} 
                        onMouseLeave={hideDropdown1}
                    >
                        <NavDropdown.Item eventKey="about" as={Link} to="/aboutus" onClick={() => setExpanded(false)}>
                        About Us
                        </NavDropdown.Item>
                        <NavDropdown.Item eventKey="contact"  as={Link} to="/contactus" onClick={() => setExpanded(false)}>
                        Contact Us
                        </NavDropdown.Item>
                        {/* <NavDropdown.Item eventKey="faq" as={Link} to="/faq">
                        FAQs
                        </NavDropdown.Item> */}
                        <NavDropdown.Item eventKey="schools" as={Link} to="/participatingschools" onClick={() => setExpanded(false)}>
                            Participating Schools
                        </NavDropdown.Item>
                    </NavDropdown>
                    <NavItem className="donate-container">
                        <Nav.Link as={Link} to="/faq" bsPrefix="donate" onClick={() => setExpanded(false)} >FAQs</Nav.Link>
                    </NavItem>
                    <NavItem className="donate-container">
                        <Nav.Link as={Link} to={{ pathname: "/resources", banner: false }} bsPrefix="donate" onClick={() => setExpanded(false)} >
                            Resources
                        </Nav.Link>
                    </NavItem>
                    <NavItem className="donate-container">
                        <Nav.Link as={Link} to="/intersectionality" bsPrefix="donate" >Intersectionality</Nav.Link>
                    </NavItem>
                    <NavItem className="donate-container">
                        <Nav.Link as={Link} to="/tomodules" bsPrefix="donate" onClick={() => setExpanded(false)} >Modules</Nav.Link>
                    </NavItem>
                    <NavItem className="donate-container">
                        <Nav.Link as={Link} to="/donate" bsPrefix="donate" >Donate</Nav.Link>
                    </NavItem>
                </Nav>
                <Nav className="mx-md-2">
                    <NavItem className="donate-container" >
                        <Nav.Link as={Link} to="/getstarted" bsPrefix="rounded-button" onClick={() => setExpanded(false)}>Get Started</Nav.Link>
                    </NavItem>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
        </header>
    );
};

export default NavBar;