import React from 'react';
import '../css/Pages.css';
import '../css/AboutUs.css';

const FAQScreen = () => {
    return (
        <div className="a-container faq-header">
            <h1> Frequently Asked Questions </h1>
            <div style={{ padding: '20px 35px 0px' }} className="faq-container">
                <h3><b>How does the tool work?</b></h3>
                <p>
                    To activate the Empowered Conversations tool on our website, complete the <span>
                        <a href="/getstarted" target="_blank" rel="noopener noreferrer">
                            anonymous form
                        </a></span> that will ask for your confidant’s first name, their phone number, and your own phone number. We
                    will then send a text message to the confidant that includes our training modules and a heads-up that someone
                    close to them would like to disclose their experience. You will also receive a text message letting you know
                    that the confidant message has been delivered.
                </p>
                <p>
                    Throughout the whole process, <b>your anonymity will remain protected.</b> Once your confidant has completed the
                    training, you will receive a text from us letting you know that your confidant may be ready to have a conversation.
                    At that point, you may initiate the conversation with your confidant whenever you feel ready.
                </p>
                <h3><b> Am I anonymous? What do you do with my personal information after I use the tool?</b> </h3>
                <p>
                    We believe in the right to data privacy and the importance of anonymity when it comes to sensitive information.
                    We encrypt and then store your cell phone number and your confidant’s first name and cell phone number.
                    This means your information is converted into an unrecognizable code that no one can understand before it is
                    stored in our database. Only our engineering staff has access to the key to decrypt the information. The encrypted
                    data is then deleted from our system after a maximum of 14 days. For more information, see our <span>
                        <a href="/privacypolicy" target="_blank" rel="noopener noreferrer">
                            privacy policy.
                        </a></span>
                </p>
                <h3><b>How many people can I send the modules to?</b></h3>
                <p>As many as you would like, there is no set limit.</p>
                <h3><b>My confidant has watched the training videos, now what?</b></h3>
                <p>
                    Your confidant is now more prepared to engage in a meaningful, effective, and
                    empowering conversation. You're welcome to initiate that conversation whenever you feel ready. If you would like
                    to prepare for the conversation, you can find resources linked at <span>
                        <a href="https://www.rainn.org/articles/telling-loved-ones-about-sexual-assault" target="_blank" rel="noopener noreferrer">
                            RAINN’s dedicated disclosure page
                        </a></span> and their <span>
                        <a href="https://www.rainn.org/articles/self-care-after-trauma" target="_blank" rel="noopener noreferrer">
                            self-care page
                        </a></span>.
                </p>
                <h3><b>What happens if my confidant doesn’t watch the videos?</b></h3>
                <p>
                    We will notify you within 14 days whether or not your confidant has completed the modules. If they do not watch
                    the videos, you may still disclose your experience to your confidant if you so choose. There are many reasons why
                    someone might not complete the modules, and this is in no way a reflection of their love or support for you. You
                    are also always welcome to <span>
                        <a href="/getstarted" target="_blank" rel="noopener noreferrer">
                            send the training to another confidant.
                        </a></span>
                </p>
                <h3><b>How do I initiate and prepare for the conversation with my confidant?</b></h3>
                <p>
                    As you prepare for the conversation with your confidant, keep in mind that you get to choose how, when, and where
                    you would like to disclose your experience, as well as how much information you share. Before the conversation takes
                    place, you may want to try some self-care activities, such as journaling, exercising, or meditating.  For more
                    information on how to initiate and prepare for a conversation, please visit RAINN’s dedicated page,&nbsp;
                    <span><a href="https://www.rainn.org/articles/telling-loved-ones-about-sexual-assault" target="_blank" rel="noopener noreferrer">
                        Telling Loved Ones about Sexual Assault
                    </a></span>, and to learn more about self-care activities visit <span><a href="https://www.rainn.org/articles/self-care-after-trauma" target="_blank" rel="noopener noreferrer">
                        Self-Care After Trauma
                    </a></span>.
                </p>
                <h3><b>What if our conversation does not go as I had hoped?</b></h3>
                <p>
                    Conversations surrounding sexual assault can be incredibly challenging, so there is a chance that your confidant
                    may not have been ready in that moment to listen effectively.
                </p>
                <p>
                    However, we believe that your story is important and your strength will not go unrecognized. If you feel comfortable,
                    you are welcome to select another confidant to send our training to. If you would like to learn more or speak to a
                    trained individual, you can find more resources on <span>
                        <a href="/resources" target="_blank" rel="noopener noreferrer">
                            our resources page
                        </a></span>
                    &nbsp;and the RAINN sexual assault hotline 1-800-656-4673 is available 24/7.
                </p>
                <h3><b>I got a text, who is it from?</b></h3>
                <p>
                    We use a third party text messaging service called Twilio where we have set up a permanent phone number to send all
                    of our text messages. For more information about Twilio, refer to <span>
                        <a href="https://www.twilio.com/legal/privacy" target="_blank" rel="noopener noreferrer">their website</a>
                    </span>.
                </p>
            </div>
        </div>
    )
};

export default FAQScreen;