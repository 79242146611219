import React, { useState } from "react";
import { useLocation } from 'react-router-dom';   
import '../css/ResourcesScreen.css';
import { LinkContainer } from "react-router-bootstrap";
import firebase from "firebase/app";
import "firebase/analytics";
import Popup from 'reactjs-popup';
import { Collapse } from '@material-ui/core';
import { GoTriangleDown } from 'react-icons/go';
import '../css/AboutUs.css';


const headers = [
    'Immediate Help', 
    'Survivors', 
    'Identity Groups',
    'Family & Friends', 
    'Legal', 
    'State Specific', 
    'Survivor Stories'
];

const ResourcesScreen = () => {
    const location = useLocation();
    const [active, setActive] = useState(location.hash ? 'Identity Groups' :'Immediate Help');
    const [expand0, setExpand0] = useState(false)
    const [expand1, setExpand1] = useState(false)
    const [expand2, setExpand2] = useState(false)
    const [expand3, setExpand3] = useState(false)
    const [expand4, setExpand4] = useState(false)
    const [expand5, setExpand5] = useState(false)
    const [expand6, setExpand6] = useState(false)


    firebase.analytics().logEvent('page_view', {
        page_path: '/resources',
        page_title: 'Resources Screen'
      });

    return (
        <div className="r-container">
            <div className="mission">
                <div>
                    {location.banner ? <Popup 
                            defaultOpen
                            position="center center"
                            modal
                            closeOnDocumentClick={true}
                            closeOnEscape={true}
                        >
                        {close => (
                            <div className="code-container">
                                <button className="close" onClick={close}>
                                    &times;
                                </button>
                                {location.banner === "form" ?
                                    <div>
                                        <div className="header"> 
                                            Thank you for filling out our form
                                        </div>
                                        <LinkContainer to="/getstarted">
                                            <button className="button-1">Submit another Confidant</button>
                                        </LinkContainer>
                                        <button className="button-1" onClick={close}>Continue to Resources</button>
                                    </div>
                                : // location.banner === modules
                                    <div>
                                        <div className="header"> 
                                            Thank you for watching our training videos
                                        </div>
                                        <button className="button-1" onClick={close}>Continue to Resources</button>
                                    </div>
                                }   
                            </div>
                        )}
                    </Popup>
                    : null
                    }
                    {/* {location.banner === "form" 
                        ? (<div className="banner"><h2> Thank you for filling out our form </h2>
                            <LinkContainer to="/getstarted">
                                <button className="button"> Submit another Confidant </button>
                            </LinkContainer></div>
                            ) 
                        : location.banner === "modules"
                            ? <div className="banner"><h2> Thank you for watching our training videos </h2></div>
                            : null
                    } */}
                </div>
                {/* <br></br> */}
                <h1 className="rtitle"> Resources </h1>
                <div style={{ padding: '20px 35px 0px' }}></div>
                <div className="r-cont-2">
                    <div className="r-nav">
                        {headers.map((name, index) => {
                            return <input
                                type="button"
                                className={active === name ? 'r-tab-active' : 'r-tab'}
                                value={name}
                                onClick={() => setActive(name)}
                                key={ name } />;
                        })}
                    </div>
                    <div className="r-body">
                        {active === headers[0] 
                            ? (
                                <div>
                                    <h1> Immediate Help Resources </h1> 
                                    <p> If you or anyone you know is in immediate danger, please call <b>911</b> </p> <br></br><br></br>
                                    <p> National Sexual Assault Telephone Hotline: Call &nbsp; 
                                        <b>800.656.HOPE (4673)</b>
                                    </p>
                                    <p> National Suicide Prevention Hotline: Call &nbsp;
                                        <b>800.273.TALK (8255) </b>
                                    </p>
                                    <p> National Domestic Violence Hotline: Call &nbsp;
                                        <b>800.799.7233</b>
                                    </p>
                                </div>
                            )
                            : active === headers[1]
                                ? (
                                    <div>
                                        <h1> Resources for Survivors </h1>
                                        <h3> RAINN (Rape, Abuse, &amp; Incest National Network) </h3>
                                            <p> <a href="https://www.rainn.org/about-national-sexual-assault-telephone-hotline" target="_blank" rel="noopener noreferrer">
                                                National Sexual Assault Telephone Hotline
                                            </a></p>
                                            <p> <a href="https://www.rainn.org/get-help" target="_blank" rel="noopener noreferrer"> 
                                                Online Chat Service 
                                            </a> </p>
                                            <p> <a href="https://www.rainn.org/lets-talk-about" target="_blank" rel="noopener noreferrer"> 
                                                “Let’s Talk About…” Comics 
                                            </a></p>
                                            <p> <a href="https://www.rainn.org/sites/default/files/FINAL_RAINN_OnePager2019-3_0.pdf" target="_blank" rel="noopener noreferrer">
                                                More about RAINN
                                            </a></p>
                                            <br></br>
                                        <h3> NSVRC (National Sexual Violence Resource Center)</h3>
                                            <p><a href="https://www.nsvrc.org/survivors" target="_blank" rel="noopener noreferrer"> 
                                                Information for Survivors
                                            </a></p>
                                            <p><a href="https://www.nsvrc.org/organizations" target="_blank" rel="noopener noreferrer">
                                                Directory of Advocacy Organizations
                                            </a></p>
                                            <br></br>
                                        <h3>End Rape on Campus</h3>
                                            <p><a href="https://endrapeoncampus.org/our-services-1" target="_blank" rel="noopener noreferrer">
                                                Services    
                                            </a></p>
                                            <p><a href="https://endrapeoncampus.org/resources#survivors" target="_blank" rel="noopener noreferrer">
                                                Resources    
                                            </a></p>
                                            <br></br>
                                        <h3>Know Your IX</h3>
                                            <p><a href="https://www.knowyourix.org/survivor-resources/" target="_blank" rel="noopener noreferrer">
                                                Survivor Resources    
                                            </a></p>
                                            <br></br>
                                        <h3>End Sexual Violence</h3>
                                            <p><a href="https://endsexualviolence.org/resources/for-survivors/" target="_blank" rel="noopener noreferrer">
                                                Survivor Resources    
                                            </a></p>
                                            <br></br>
                                        <h3>&amp;Rise </h3>
                                            <p><a href="https://www.womenrisechicago.org/rise-services" target="_blank" rel="noopener noreferrer">
                                                Services    
                                            </a></p>
                                            <br></br>
                                        <h3>No More</h3>
                                            <p><a href="https://nomore.org/need-immediate-help/" target="_blank" rel="noopener noreferrer">
                                                Services    
                                            </a></p>
                                            <br></br>
                                        <h3>One Love</h3>
                                            <p><a href="https://www.joinonelove.org/get-help/#real-time" target="_blank" rel="noopener noreferrer">
                                                Get Help    
                                            </a></p>
                                    </div>
                                )
                                : active === headers[2] 
                                    ? (
                                        <div style={{ paddingBottom: '30px' }}>
                                            <h1> Resources for Identity Groups </h1>
                                            <div onClick={() => setExpand1(!expand1)} className="identities asian-pacisl"><h3>Asian / Pacific Islander Community <GoTriangleDown /></h3>
                                                <Collapse in={expand1} classes={{ entered: 'id_groups', wrapper: 'id_groups', wrapperInner: 'id_groups'}}>
                                                <p><a href="http://www.kanwin.org/sexualassault/" target="_blank" rel="noopener noreferrer">
                                                    KAN-WIN     
                                                </a></p>
                                                <p><a href="https://www.api-gbv.org/" target="_blank" rel="noopener noreferrer">
                                                    Asian Pacific Institute on Gender-Based Violence     
                                                </a></p>
                                                <p><a href="https://napiesv.org/" target="_blank" rel="noopener noreferrer">
                                                    NAPIESV (National Organization of Asians and Pacific Islanders Ending Sexual Violence)     
                                                </a></p>
                                                <p><a href="https://www.accesscal.org/" target="_blank" rel="noopener noreferrer">
                                                    Access California Services     
                                                </a></p>
                                                <p><a href="https://annisa.org/" target="_blank" rel="noopener noreferrer">
                                                    An-Nisa Hope Center
                                                </a></p>
                                                <p><a href="https://www.apichaya.org/" target="_blank" rel="noopener noreferrer">
                                                    API Chaya
                                                </a></p>
                                                <p><a href="https://ashakiranonline.org/" target="_blank" rel="noopener noreferrer">
                                                    AshaKiran
                                                </a></p>
                                                <br></br>
                                                </Collapse>
                                            </div>
                                            <div onClick={() => setExpand5(!expand5)} className="identities"><h3>Adult Survivors of Childhood Sexual Abuse <GoTriangleDown /></h3>
                                                <Collapse in={expand5} classes={{ entered: 'id_groups', wrapper: 'id_groups', wrapperInner: 'id_groups'}}>
                                                <p><a href="https://siawso.org/" target="_blank" rel="noopener noreferrer">
                                                    Survivors of Incest Anonymous     
                                                </a></p>
                                                <p><a href="https://www.stopitnow.org/ohc-content/resources-and-support-for-adults-who-experienced-sexual-abuse-as-children" target="_blank" rel="noopener noreferrer">
                                                    Stop It Now    
                                                </a></p>
                                                <p><a href="https://www.nsvrc.org/sites/default/files/Publications_RSP_Action-engagement-remembering-services-for-adult-survivors-child-sexual-abuse.pdf" target="_blank" rel="noopener noreferrer">
                                                    NSVRC (National Sexual Violence Resource Center)
                                                </a></p>
                                                <br></br>
                                                </Collapse>
                                            </div>
                                            <div onClick={() => setExpand2(!expand2)} className="identities"><h3>Black Community <GoTriangleDown /></h3>
                                            <Collapse in={expand2} classes={{ entered: 'id_groups', wrapper: 'id_groups', wrapperInner: 'id_groups'}}>
                                                <p><a href="https://ujimacommunity.org/" target="_blank" rel="noopener noreferrer">
                                                    Ujima     
                                                </a></p>
                                                <p><a href="https://www.blackwomensblueprint.org/" target="_blank" rel="noopener noreferrer">
                                                    Black Women's Blueprint    
                                                </a></p>
                                                <p><a href="https://bwhi.org/signature-programs/" target="_blank" rel="noopener noreferrer">
                                                    Black Women's Health Imperative: Signature Programs    
                                                </a></p>
                                                <p><a href="https://nbjc.org/nbjc-resources_/" target="_blank" rel="noopener noreferrer">
                                                    National Black Justice Coalition    
                                                </a></p>
                                                <p><a href="https://sisterslead.org/" target="_blank" rel="noopener noreferrer">
                                                    National Coalition of Sisters of Color Ending Sexual Assault   
                                                </a></p>
                                                <p><a href="https://www.twocc.us/" target="_blank" rel="noopener noreferrer">
                                                    Trans Women of Color Collective     
                                                </a></p>
                                                <p><a href="https://rights4girls.org/resources/" target="_blank" rel="noopener noreferrer">
                                                    Rights4Girls   
                                                </a></p>
                                                <p><a href="https://www.sisterlove.org/what-we-do" target="_blank" rel="noopener noreferrer">
                                                        SisterLove
                                                </a></p>
                                                <p><a href="http://www.alongwalkhome.org/programs/" target="_blank" rel="noopener noreferrer">
                                                    A Long Walk Home    
                                                </a></p>
                                                <br></br>
                                                </Collapse>
                                            </div>
                                            <div onClick={() => setExpand3(!expand3)} className="identities"><h3>Indigenous Community <GoTriangleDown /></h3>
                                                <Collapse in={expand3} classes={{ entered: 'id_groups', wrapper: 'id_groups', wrapperInner: 'id_groups'}}>
                                                <p><a href="https://strongheartshelpline.org/" target="_blank" rel="noopener noreferrer">
                                                    StrongHearts Native Hotline     
                                                </a></p>
                                                <br></br>
                                                </Collapse>
                                            </div>
                                            <div onClick={() => setExpand4(!expand4)} className="identities"><h3>Latin / Hispanic Community <GoTriangleDown /></h3>
                                                <Collapse in={expand4} classes={{ entered: 'id_groups', wrapper: 'id_groups', wrapperInner: 'id_groups'}}>
                                                <p><a href="https://www.healingtoaction.org/" target="_blank" rel="noopener noreferrer">
                                                    Healing to Action     
                                                </a></p>
                                                <br></br>
                                                </Collapse>
                                            </div>
                                            <div onClick={() => setExpand0(!expand0)} className="identities"><h3>LGBTQ+ Community <GoTriangleDown /></h3>
                                                <Collapse in={expand0} classes={{ entered: 'id_groups', wrapper: 'id_groups', wrapperInner: 'id_groups'}}>
                                                <p><a href="https://www.loveisrespect.org/" target="_blank" rel="noopener noreferrer">
                                                    Love is Respect    
                                                </a></p>
                                                <p><a href="https://avp.org/get-help/" target="_blank" rel="noopener noreferrer">
                                                    Anti-Violence Project   
                                                </a></p>
                                                <p><a href="https://www.glbthotline.org/" target="_blank" rel="noopener noreferrer">
                                                    GLBT Hotline
                                                </a></p>
                                                <p><a href="https://forge-forward.org/resources/" target="_blank" rel="noopener noreferrer">
                                                    FORGE    
                                                </a></p>
                                                <p><a href="https://www.nwnetwork.org/" target="_blank" rel="noopener noreferrer">
                                                    The NW Network  
                                                </a></p>
                                                <p><a href="https://www.rainn.org/articles/lgbtq-survivors-sexual-violence" target="_blank" rel="noopener noreferrer">
                                                    RAINN (Rape, Abuse, &amp; Incest National Network)
                                                </a></p>
                                                <p><a href="https://www.thetrevorproject.org/" target="_blank" rel="noopener noreferrer">
                                                    The Trevor Project
                                                </a></p>
                                                <br></br>
                                                </Collapse>
                                            </div>
                                            <div onClick={() => setExpand6(!expand6)} className="identities"><h3>Male Identifying Individuals <GoTriangleDown /></h3>
                                                <Collapse in={expand6} classes={{ entered: 'id_groups', wrapper: 'id_groups', wrapperInner: 'id_groups'}}>
                                                <p><a href="https://supportgroup.1in6.org/" target="_blank" rel="noopener noreferrer">
                                                    1in6    
                                                </a></p>
                                                <p><a href="https://malesurvivor.org/for-survivors/" target="_blank" rel="noopener noreferrer">
                                                    MaleSurvivor    
                                                </a></p>
                                                <p><a href="https://www.rainn.org/articles/sexual-assault-men-and-boys" target="_blank" rel="noopener noreferrer">
                                                    RAINN (Rape, Abuse, &amp; Incest National Network)    
                                                </a></p>
                                                </Collapse>
                                            </div>
                                        </div>
                                    )
                                    : active === headers[3] 
                                        ? (
                                            <div>
                                                <h1> Resources for Family &amp; Friends </h1>
                                                <h3> RAINN (Rape, Abuse, &amp; Incest National Network) </h3>
                                                    <p><a href="https://www.rainn.org/articles/self-care-friends-and-family" target="_blank" rel="noopener noreferrer">
                                                        Self Care for Friends and Family
                                                    </a></p>
                                                    <br></br>
                                                <h3> As One Project </h3>
                                                    <p><a href="https://asoneproject.org/ " target="_blank" rel="noopener noreferrer">
                                                            About
                                                    </a></p>
                                                    <p><a href="https://asoneproject.org/get-help/" target="_blank" rel="noopener noreferrer">
                                                            Support a Survivor
                                                    </a></p>
                                                    <br></br>
                                                <h3> Know Your IX </h3>
                                                    <p><a href="https://www.knowyourix.org/for-friends-and-fami/" target="_blank" rel="noopener noreferrer">
                                                            Friends &amp; Family Resources
                                                    </a></p>
                                                    <br></br>
                                                <h3> Joyful Heart Foundation </h3>
                                                    <p><a href="http://www.joyfulheartfoundation.org/blog/6-steps-supporting-survivor" target="_blank" rel="noopener noreferrer">
                                                        6 Steps to Supporting a Survivor
                                                    </a></p>
                                                    <p><a href="http://www.joyfulheartfoundation.org/espanol" target="_blank" rel="noopener noreferrer">
                                                        Resources in Spanish
                                                    </a></p>
                                                    <br></br>
                                                <h3> 1in6 </h3>
                                                    <p><a href="https://1in6.org/get-information/for-family-and-friends-of-men/" target="_blank" rel="noopener noreferrer">
                                                        For Family &amp; Friends
                                                    </a></p>
                                                    <br></br>
                                            </div>
                                        )
                                        : active === headers[4]
                                            ? (<div>
                                                <h1> Legal Resources </h1>
                                                <h3> RAINN (Rape, Abuse, &amp; Incest National Network) </h3>
                                                    <p><a href="https://apps.rainn.org/policy/" target="_blank" rel="noopener noreferrer">
                                                        State Law Database
                                                    </a></p>
                                                    <br></br>
                                                <h3> Victim Rights </h3>
                                                    <p><a href="https://www.victimrights.org/" target="_blank" rel="noopener noreferrer">
                                                        Law Center
                                                    </a></p>
                                                    <br></br><br></br>
                                                <h1>Legal Definitions of Sexual Assault and Other Forms of Sexual Violence</h1>
                                                <h3>National Institute of Justice</h3>
                                                    <p><a href="https://nij.ojp.gov/topics/articles/overview-rape-and-sexual-violence" target="_blank" rel="noopener noreferrer">
                                                        Rape and Sexual Violence
                                                    </a></p>
                                                    <br></br>
                                                <h3>U.S. Equal Employment Opportunity Commission</h3>
                                                    <p><a href="https://www.eeoc.gov/fact-sheet/facts-about-sexual-harassment" target="_blank" rel="noopener noreferrer">
                                                        Facts About Sexual Harassment
                                                    </a></p>
                                                    <br></br>
                                                <h3>RAINN</h3>
                                                    <p><a href="https://www.rainn.org/types-sexual-violence" target="_blank" rel="noopener noreferrer">
                                                        Types of Sexual Violence
                                                    </a></p>
                                            </div>)
                                            : active === headers[5]
                                                ? (<div>
                                                    <h1> State Specific Resources </h1>
                                                    <h3> RAINN (Rape, Abuse, &amp; Incest National Network) </h3>
                                                        <p><a href="https://www.rainn.org/state-resources" target="_blank" rel="noopener noreferrer">
                                                            State Resources
                                                        </a></p>
                                                        <br></br>
                                                    <h3> NSVRC (National Sexual Violence Resource Center) </h3>
                                                        <p><a href="https://www.nsvrc.org/organizations/state-and-territory-coalitions" target="_blank" rel="noopener noreferrer">
                                                            State or Territory Sexual Assault Coalitions
                                                        </a></p>
                                                        <br></br>
                                                    <h3> CDC </h3>
                                                        <p><a href="https://www.cdc.gov/lgbthealth/health-services.htm" target="_blank" rel="noopener noreferrer">
                                                            LGBTQ Health Services: By State
                                                        </a></p>
                                                </div>)
                                                : (<div>
                                                    <h1> Survivor Stories </h1>
                                                    <h3> The Voices and Faces Project </h3>
                                                        <p><a href="https://voicesandfaces.org/about/" target="_blank" rel="noopener noreferrer">
                                                            Survivor Storytelling
                                                        </a></p>
                                                        <br></br>
                                                    <h3> Take Back the Night </h3>
                                                        <p><a href="https://takebackthenight.org/survivor-stories/" target="_blank" rel="noopener noreferrer">
                                                            Survivor Stories
                                                        </a></p>
                                                        <br></br>
                                                    <h3> Vera House </h3>
                                                        <p><a href="https://www.verahouse.org/hope-and-healing/survivor-stories" target="_blank" rel="noopener noreferrer">
                                                            Survivor Stories
                                                        </a></p>
                                                        <br></br>
                                                    <h3> The Survivors Trust </h3>
                                                        <p><a href="https://www.thesurvivorstrust.org/blogs/survivor-stories" target="_blank" rel="noopener noreferrer">
                                                            Survivor Stories
                                                        </a></p>
                                                        <br></br>
                                                    <h3> RAINN (Rape, Abuse, &amp; Incest National Network) </h3>
                                                        <p><a href="https://www.rainn.org/stories" target="_blank" rel="noopener noreferrer">
                                                            Survivor Stories
                                                        </a></p>
                                                </div>)
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ResourcesScreen;