import React from "react";
import { Link } from "react-router-dom";
import LogoBubble from "../img/Logo_Bubble.png";
import FacebookIcon from '../img/facebook.png';
import InstagramIcon from '../img/instagram.png'
import '../css/Footer.css';

const Footer = () => {
    return (
      <footer className="footer-container">
        <div className="row h-100 w-100 no-gutters">
            {/* <div className="row w-100" style={{ textAlign: 'center' }}> */}
                <div className="col-sm-4 h-100 f-col-1 order-sm-1"> 
                    <div className="col w-100">
                        <img src={LogoBubble} alt="Empowered Conversations" className="f-logo"/>
                    </div>
                    <div className="row w-100">
                        <div className="col-xs-4 col-xs-offset-2 f-terms">
                            <Link to="/termsofservices">Terms of Services</Link>
                        </div>
                        <div className="col-xs-4 f-privacy">
                            <Link to="/privacypolicy">Privacy Policy </Link>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4 h-100 f-email order-sm-0"> 
                    <a href="/contactus" >
                        <p>info@empoweredconversations.org</p>
                    </a>
                </div>
                <div className="col-sm-4 h-100 f-media order-sm-3">
                    <a href="https://www.facebook.com/EmpoweredConversations1" target="_blank" rel="noopener noreferrer">
                        <img src={FacebookIcon} alt="Facebook" className="f-icon" />
                    </a>
                    <a href="https://www.instagram.com/empowered.conversations/" target="_blank" rel="noopener noreferrer">
                        <img src={InstagramIcon} alt="Instagram" className="f-icon" />
                    </a>
                </div>
            {/* </div> */}
            {/* <div className="row w-50 tsp-container">
                <div className="col f-terms">
                    <Link to="/termsofservices">Terms of Services</Link>
                </div>
                <div className="col f-privacy">
                    <Link to="/privacypolicy">Privacy Policy</Link>
                </div>
            </div> */}
            {/* <div className="row">
            <div className="col" style={{ fontSize: "0.8rem", marginLeft: '-7px', marginTop: '-3px' }}>
                &copy; Empowered Conversations 2019. All rights reserved.
            </div>
            </div> */}
        </div>
      </footer>
    );
  }

export default Footer;
