import React from "react";
import Card from "react-bootstrap/Card";
import '../css/Pages.css';

const TermsOfServices = () => {
  return (
    <Card className="terms-card" id="top">
      <Card.Header>
        <h6>Empowered Conversations</h6>
        <h2>Terms of Services</h2>
      </Card.Header>
      <Card.Body>
        <p>
          Empowered Conversations seeks to <b>promote effective, authentic, and empathetic conversations between survivors of sexual assault and their family members and friends.</b> Our survivor-activated tool educates and prepares confidants before the conversation takes place.  When a survivor is ready to disclose their sexual assault to a confidant, they can submit their cell phone number as well as the number and first name of the confidant. The confidant then receives a text message from Empowered Conversations that informs them that someone they love wants to have a conversation about sexual assault, and provides them with trauma-informed information on assault, how best to respond, and what to avoid so as not to re-trigger the survivor. Once the confidant has viewed the information, we automatically send a text to the survivor to let them know.
        </p>
        <div className="my-4 ml-4">
          <a href="#binding">Binding Agreement</a>
          <a href="#usingService">Using Our Service</a>
          <a href="#individualsOnly" className="indent">
            Empowered Conversations is for individuals only
          </a>
          <a href="#usOnly" className="indent">
            Empowered Conversations is offered only in the US
          </a>
          <a href="#minAge" className="indent">
            Minimum age to use Empowered Conversations
          </a>
          <a href="#survivorsOnly" className="indent">
            The service must only be on your own behalf
          </a>
          <a href="#noFraud" className="indent">
            No fraudulent accounts or activities
          </a>
          <a href="#rightDenyService" className="indent">
            Empowered Conversations’ right to deny service
          </a>
          <a href="#rightsLicenses">
            Empowered Conversations’ Rights and Licenses
          </a>
          <a href="#rightsWebsiteServices" className="indent">
            Our rights to our website and services
          </a>
          <a href="#yourLicense" className="indent">
            Your license to Empowered Conversations for your content
          </a>
          <a href="#noGuarantee">No Guarantees that the Service will work</a>
          <a href="#noGuaranteeAllyship" className="indent">
            No guaranteed allyship
          </a>
          <a href="#noGuaranteeService" className="indent">
            No guarantee of service
          </a>
          <a href="#asIsService" className="indent">
            As is service
          </a>
          <a href="#legalProtection">
            Legal Protection of Empowered Conversations
          </a>
          <a href="#linksThirdParty" className="indent">
            Links to third party resources
          </a>
          <a href="#noWaiver" className="indent">
            No waiver
          </a>
          <a href="#limitationLiability" className="indent">
            Limitation of liability
          </a>
          <a href="#indemnity" className="indent">
            Indemnity
          </a>
          <a href="#gooverningLawDisputes">Dispute Resolution</a>
          <a href="#assignmentUpdatesSurvival">
            Assignment, Updates, Duration and Survival of these Terms
          </a>
          <a href="#assignment" className="indent">
            Assignment
          </a>
          <a href="#updates" className="indent">
            Updates
          </a>
          <a href="#durationSurvival" className="indent">
            Duration and Survival
          </a>
          <a href="#contact">How to Contact Us</a>
        </div>
        <h3><span id="binding"></span>1. Binding Agreement</h3>
        <p>
          For us to fulfill our mission, our service needs to be safe, and we
          must ensure that it’s used only by survivors and only as intended.
          <b>These Terms of Service are a set of rules that
          we enforce to keep our service safe for our users, the confidants, for
          us, and for third parties. </b>They also outline rights and
          responsibilities of Empowered Conversations and its users.
        </p>
        <p>
          By using our service and submitting your contact information and the
          contact information of the confidant, you agree to abide by these
          Terms and you consent to Empowered Conversations collecting, using, and
          sharing information about you and the confidant as stated in our
          Privacy Policy. If you do not agree to these Terms, do not use our
          service.
        </p>
        <h3><span id="usingService"></span>2. Using Our Service</h3>
        <h5><span id="individualsOnly"></span>
          Empowered Conversations is for individuals only
        </h5>
        <p>
          You must be an individual person to use Empowered Conversations. You
          may not use the service or submit contact information for or on behalf
          of a company, organization, government, or other legal entity.
          Empowered Conversations may partner with professional organizations,
          companies, and other types of organizations (such as higher education
          institutions) to offer our service to individuals who are members of
          these organizations or communities. If you would like Empowered
          Conversation to be accessible to members of your community, you can
          suggest a network by contacting us at info@empoweredconversations.org.
        </p>
        <h5><span id="usOnly"></span>Empowered Conversations is offered only in the US</h5>
        <p>
          While we would love to help survivors all around the world, our
          service is designed only for people who live in the United States,
          regardless of your immigration status (just living in the United
          States, even temporarily, qualifies you for our service).
        </p>
        <h5><span id="minAge"></span>Minimum age to use Empowered Conversations</h5>
        <p>
          You have to be at least 13 years or older to use our service and
          submit your contact information to us. If we discover that a person
          younger than 13 has provided us their contact information, we will
          immediately delete their information from our systems and not provide
          the service.
        </p>
        <h5><span id="survivorsOnly"></span>The service must only be on your own behalf</h5>
        <p>
          Even if you consider yourself the supporter or ally of a survivor of
          sexual assault, do not use the service on behalf of someone else. It
          is important that every confidant contact information is submitted by
          the survivors themselves, so that these survivors are fully empowered
          to make decisions about disclosing their assault. If we discover that
          an entry has been made by a person who was not the survivor of an
          assault, we will immediately delete the contact information and not
          provide the service.
        </p>
        <h5><span id="noFraud"></span>
          No fraudulent accounts or activities, no fake contact information
        </h5>
        <p>
          When using our service, you must provide accurate contact information,
          including valid cell phone numbers that are yours and the confidants,
          and not someone else’s. Do not impersonate anyone or use fake contact
          information. We will immediately terminate service to any user that we
          suspect is fake or fraudulent. If we suspect that contact information
          has been submitted fraudulently, or that our service is used in a way
          that is not aligned with our mission to protect and support survivors,
          we may immediately cease providing the service.
        </p>
        <p>
          If you discover or suspect that someone else is using your cell phone
          number without your permission, let us know immediately by contacting
          us at info@empoweredconversations.org.
        </p>
        <h5><span id="rightDenyService"></span>
          Empowered Conversations’ right to deny service
        </h5>
        <p>
          Empowered Conversations has discretion in enforcing these Terms. We may
          terminate or suspend your access to our website and service at any
          time, and prevent you from further access to our services, with or
          without notice to you, for any violation of these Terms. We will have
          no liability to you or any third party for removal of your submitted
          contact information or blocking of your access to our website and
          services.
        </p>
        <h3><span id="rightsLicenses"></span>
          3. Empowered Conversations’ Rights and Licenses
        </h3>
        <h5><span id="rightsWebsiteServices"></span>
          Our rights to our website and services
        </h5>
        <p>
          All rights, titles, and interests in and to the Empowered Conversations
          website and service including our website, any future applications,
          our databases, and the content provided through our website and
          service are and will remain the exclusive property of Empowered
          Conversation and its licensors. 
        </p>
        <p>
          You may not do any of the following while accessing or using the
          Empowered Conversations website and service:
        </p>
        <ol>
          <li>
            access, tamper with, or use non-public areas of the website or
            service, computer systems, or the technical delivery systems of
            Empowered Conversations and its service providers
          </li>
          <li>
            disable, interfere with, or try to circumvent any of the features of
            the website related to security or probe, scan, or test the
            vulnerability of any of our systems
          </li>
          <li>
            copy, modify, create a derivative work of, reverse engineer, reverse
            assemble, or otherwise attempt to discover any infrastructure, or
            server configurations of Empowered Conversations
          </li>
          <li>
            access or search or attempt to access or search our databases by any
            means (automated or otherwise); scrape, spider, use a robot, or use
            other automated means of any kind to access our website, service or
            databases
          </li>
          <li>
            in any way use the Empowered Conversations service to send altered,
            deceptive, or false source-identifying information; or interfere
            with, or disrupt, (or attempt to do so), the access of any user,
            host, or network, including, without limitation, sending a virus,
            overloading, flooding, spamming, denial of service attacks,
            mailbombing, or in any other manner interfering with or creating an
            undue burden on the services.
          </li>
        </ol>
        <h5><span id="yourLicense"></span>
          Your license to Empowered Conversations for content you submit
        </h5>
        <p>
          When you submit to us your testimonials, feedback about our service, or
          ideas and suggestions for new features or improvements, you authorize
          Empowered Conversations to use this content for any legitimate purpose,
          including for promotion of Empowered Conversations on our website or on
          social media platforms, and to make modifications or edits to it as we
          see fit. You also agree to all such uses of your content with no
          compensation paid to you. We promise however that we will never post
          any information that identifies you without your consent. See our
          Privacy Policy or more details.
        </p>
        <h3><span id="noGuarantee"></span>4. No Guarantee that the Service will Work</h3>
        <h5><span id="noGuaranteeAllyship"></span>No guaranteed allyship</h5>
        <p>
          Empowered Conversations has been created to help survivors be supported
          in the best way possible by their friends and family. However, we
          cannot guarantee that the confidant you designate will react as
          desired or expected or that they will be the best possible ally for
          you. There are always emotional, social and legal risks in approaching
          a third party, even a close friend or family member, to disclose
          sexual assault. We hope but cannot guarantee that our service
          mitigates these risks.
        </p>
        <h5><span  id="noGuaranteeService"></span>No guarantee of service</h5>
        <p>
          We may decide to cease making available the Empowered Conversations
          website or service or any portion thereof at any time and for any
          reason. Under no circumstances will Empowered Conversations or its
          affiliates, suppliers, partners or agents be held liable for any
          damages due to such interruptions or lack of availability. We will not
          be liable to you for any delay or failure to perform any service as
          indicated on our website.
        </p>
        <h5><span id="asIsService"></span>As is service</h5>
        <p>
          Use of the Empowered Conversations website and service is at your own
          risk. These (including any content you find on or via our website or
          future applications) are provided on an “<b>as is</b>” and “
          <b>as available</b>” basis. Empowered Conversations and its affiliates,
          suppliers, partners, and agents expressly disclaim all warranties of
          any kind, whether express or implied, including, but not limited to,
          the implied warranties of title, merchantability, fitness for a
          particular purpose and non-infringement.
        </p>
        <p>
          Empowered Conversations and its affiliates, suppliers, partners and
          agents make no warranty that (i) our website or any service provided
          will meet your requirements; (ii) the website or service will be
          uninterrupted, timely, secure, or error-free or that Empowered
          Conversation will fix any errors; (iii) you will obtain any specific
          results from use of the website or service; or (iv) any content on the
          website or service is complete, current, or error-free.
        </p>
        <p>
          Any content obtained through the website or service is obtained at
          your own discretion and risk and Empowered Conversations shall not be
          responsible for any damage caused to your computer or data or for any
          bugs, viruses, Trojan horses, or other destructive code resulting from
          use of the website or service.
        </p>
        <p>
          Some states do not allow the exclusion of implied warranties, so the
          above exclusion may not apply to you. You may also have other legal
          rights which vary from state to state.
        </p>
        <h3><span id="legalProtection"></span>
          5. Other Legal Protections of Empowered Conversations
        </h3>
        <h5><span id="linksThirdParty"></span>Links to third party resources</h5>
        <p>
          When you use our website, you may find links to other websites and
          publications that we suggest as resources for survivors. While we are
          careful about curating resources that will be helpful, we do not own
          nor control these websites or publications, and therefore we are not
          responsible for the content or any other aspect of these third party
          sites, including their collection of information about you. You should
          read their terms and conditions and privacy policies.
        </p>
        <h5><span id="noWaiver"></span>No waiver</h5>
        <p>
          Even if we are delayed in exercising our rights or fail to exercise a
          right in one case, it does not mean we waive our rights under these
          Terms, and we may decide to enforce them in the future. If we decide
          to waive any of our rights in a particular instance, it does not mean
          we waive our rights generally or in the future.
        </p>
        <h5><span id="limitationLiability"></span>Limitation of liability</h5>
        <p>
          <b>
            To the fullest extent permitted under law, Empowered Conversations
            and its affiliates, suppliers, partners and agents have no
            obligation or liability (whether arising in contract, warranty, tort
            (including negligence), product liability, or otherwise) for any
            indirect, incidental, special, punitive, or consequential damages or
            liabilities (including, but not limited to, any loss of data or
            content, or personal injury or death) arising
            from or related to your use of the website or service, or any
            content provided, even if we have been advised of the possibility of
            such damages in advance. This limitation applies to damages arising
            from (i) your use or inability to use the website and access the
            service; (ii) cost of procurement of substitute services; (iii)
            unauthorized access to or alteration or distribution of content you
            submit through the service; (iv) third party content made available
            to you through the website or service; or (v) any other matter
            relating to any aspect of the website or service, including any
            future applications, email communications, and Empowered
            Conversation content on third party sites.
          </b>{" "}
          Some countries and states do not allow the limitation or exclusion of
          incidental, consequential, or other types of damages, so some of the
          above limitations may not apply to you.
        </p>
        <p>
          <b>
            Notwithstanding anything to the contrary contained herein, Empowered
            Conversation’s liability and the liability of each of its
            affiliates, suppliers, partners, and agents to you or any third
            parties under any circumstance is limited to one hundred dollars
            ($100).
          </b>
        </p>
        <h5><span id="indemnity"></span>Indemnity</h5>
        <p>
          You agree to indemnify, defend (if we so request), and hold harmless
          Empowered Conversations and its affiliates, suppliers, partners, and
          agents from and against any claims, demands, losses, damages, or
          expenses (including reasonable attorneys’ fees) arising from the
          information you submit, your use of the Empowered Conversations
          website, future applications, or service, your violation of these
          Terms or your violation of any rights of any third-party.
        </p>
        <h3><span id="governingLawDisputes"></span>6. Governing Law and Disputes</h3>
        <p>
          Empowered Conversations is an Illinois based organization and these
          Terms are governed by laws of the State of Michigan and the United
          States of America, without regard to their conflict of law principles.
          If a court having proper authority decides that any portion of these
          terms, policies, and guidelines are invalid, only the part that is
          invalid will not apply and the rest will still be in effect.
        </p>
        <p>
          In the event of any dispute between you and us arising from or
          relating to these Terms or your use of our website, future
          applications or service, we shall use our respective best efforts to
          settle the dispute. If a dispute arises, the complaining party shall
          provide written notice to the other party in a document specifically
          entitled “Notice of Dispute,” specifically setting forth the precise
          nature of the dispute. Please send any Notice of Dispute to us at:
          Empowered Conversations, 1745 Orrington Ave Apt. 1N, Evanston IL 60201. 
          Following Empowered Conversations’ receipt of
          the Notice of Dispute, the parties shall consult and negotiate with
          each other in good faith and, recognizing their mutual interest,
          attempt to reach a just and equitable solution of the dispute that is
          satisfactory to both parties.
        </p>
        <p>
          If the parties are unable to reach a resolution of the dispute through
          this dispute resolution mechanism within 90 days of the receipt of the
          Initial Notice of Dispute, then any subsequent claim, legal
          proceedings, or litigation arising in connection with the Empowered
          Conversation website, application or service will be brought solely in
          the federal or state courts located in Cook County, Illinois,
          United States, and you consent to the jurisdiction of and venue in
          such courts and waive any objection as to inconvenient forum.
        </p>
        <h3><span id="assigmentUpdatesSurvival"></span>
          7. Assignment, Updates, Duration and Survival of these Terms
        </h3>
        <h5><span id="assignment"></span>Assignment</h5>
        <p>
          These Terms, and any rights and licenses granted to you under these
          Terms, may not be transferred or assigned by you, but may be assigned
          by us to another organization, company or person without restriction.
        </p>
        <h5><span id="updates"></span>Updates</h5>
        <p>
          From time to time, we may update these Terms to clarify our practices
          or to reflect new or different practices (such as when we add new
          services or features), and Empowered Conversations reserves the right
          in its sole discretion to modify and/or make changes to these Terms at
          any time. 
        </p>
        <p>
          Your continued use of our website, applications and service after
          changes become effective shall mean that you accept those changes. Any
          revised Terms shall supersede all previous Terms.
        </p>
        <h5><span id="durationSurvival"></span>Duration and Survival</h5>
        <p>
          These Terms are in force and applicable to you for as long as you
          continue to use Empowered Conversations. Sections 3 through 7 shall
          survive the expiration or termination of these Terms.
        </p>
        <h3><span id="contact"></span>How to Contact Us</h3>
        <p>
          We would love to hear your questions, concerns, and feedback about
          these Terms and our website and service. If you have any questions or
          suggestions for us, or need help, feel free to contact us. Because
          email communications are not always secure, please do not include
          sensitive information in your messages to us.
        </p>
      </Card.Body>
    </Card>
  );
};

export default TermsOfServices;
