import React from "react";
import '../css/Pages.css';

const NotFound = () => {
  return (
    <div className="m-intro-container">
      <h2>Page in progress</h2>
    </div>
  );
};

export default NotFound;