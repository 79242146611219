import React, { useState } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
//import Thermometer from 'react-thermometer';
import '../css/Pages.css';

const DonateScreen = () => {
    return (
        <div>
            <div className="donate-banner">
                <a href="https://www.paypal.com/donate?hosted_button_id=L8NNJRQLYH5VW" target="_blank" rel="noopener noreferrer">
                    <h1>Donate</h1>
                </a>
                <p>Empowered Conversations seeks to promote effective, authentic, and empathetic conversations between survivors of sexual assault and their family members and friends. Our survivor-activated tool educates and prepares confidants before the conversation takes place. As a recently established 501(c)3 tax deductible non-profit, we are seeking donations to enable us to expand the reach of our service to help more survivors and their family members and friends.</p>
            </div>
            
            <div className="therm-cont">
                <div className="donation-blurbs">
                    <a href="https://www.paypal.com/donate?hosted_button_id=L8NNJRQLYH5VW" target="_blank" rel="noopener noreferrer">
                        <h2>Your $5 donation:</h2>
                    </a>
                    <p>Helps Empowered Conversations pay for the promotion of one Instagram post to help us reach and support more survivors.</p>                    
                    <a href="https://www.paypal.com/donate?hosted_button_id=L8NNJRQLYH5VW" target="_blank" rel="noopener noreferrer">
                        <h2>Your $25 donation:</h2>
                    </a>
                    <p>Helps Empowered Conversations identify and partner with survivor activists to expand our professional network.</p>
                    <a href="https://www.paypal.com/donate?hosted_button_id=L8NNJRQLYH5VW" target="_blank" rel="noopener noreferrer">
                        <h2>Your $50 donation:</h2>
                    </a>
                    <p>Helps Empowered Conversations subscribe to academic research databases and journals to ensure that the information we are providing is consistent with current science.</p>
                    <a href="https://www.paypal.com/donate?hosted_button_id=L8NNJRQLYH5VW" target="_blank" rel="noopener noreferrer">
                        <h2>Your $100 donation:</h2>
                    </a>
                    <p>Helps Empowered Conversations facilitate info sessions and outreach events on college campuses to expand our presence.</p>
                    <a href="https://www.paypal.com/donate?hosted_button_id=L8NNJRQLYH5VW" target="_blank" rel="noopener noreferrer">
                        <h2>Your $250 donation:</h2>
                    </a>
                    <p>Helps Empowered Conversations host a survivor focused event to provide visibility and validity to stigmatized experiences.</p>
                </div>
            </div> 
            {/* <LinkContainer to='https://www.paypal.com/donate?hosted_button_id=L8NNJRQLYH5VW'> */}
            <a href="https://www.paypal.com/donate?hosted_button_id=L8NNJRQLYH5VW" target="_blank" rel="noopener noreferrer">
                <div className="b-container">
                    <button className="rounded-button"> Donate </button>
                </div>
            </a>
            {/* </LinkContainer> */}
        </div>
    )
};

export default DonateScreen;