import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import '../css/FormIntroScreen.css';
import { LinkContainer } from 'react-router-bootstrap'
// import PilotForm from '../components/PilotForm';
// import Logo_Bubble from '../img/Logo_Bubble.png';
// import FormScreen from './FormScreen';


// const handleContinue = () => {
//     return (
//         <FormScreen />
//     );
// };

const FormIntroScreen = () => {
    const [agreed, setAgreed] = useState(false);
    const [popup, setPopup] = useState(false);

    const handleClick = () => {
        setAgreed(!agreed);
    }

    return (
        // <React.Fragment>
            <div className="cont">
                {/* <PilotForm/> */}
                <h1> Using the Empowered Conversations Tool </h1>
                <div className="blurb">
                    <h3><b> What Happens: </b></h3>
                    <p>
                        After you fill out our form, providing us with your chosen confidant and phone number, 
                        we will then send a text message to the confidant that includes our training modules 
                        and a heads-up that someone close to them would like to disclose their experience. 
                        You will also receive a text message letting you know that the confidant message has 
                        been delivered. Once your confidant has completed the training, you will receive a text 
                        from us letting you know that your confidant may be ready to have a conversation. At that 
                        point, you may initiate the conversation with your confidant whenever you feel ready.
                        <span style={{ fontWeight: '500' }}> As a part of the pilot program, we are also conducting an anonymous feedback survey. 
                        We will send you an additional text to ask for your consent to receive this survey. </span>
                    </p>
                </div>
                <div className="blurb">
                    <h3><b> Your Privacy: </b></h3>
                    <p>
                        Throughout the whole process, your anonymity will remain protected. We encrypt and then 
                        store your cell phone number and your confidant’s first name and cell phone number. This 
                        means your information is converted into an unrecognizable code that no one can understand 
                        before it is stored in our database. Only our engineering staff has access to the key to 
                        decrypt the information. The encrypted data is then typically deleted from our system after a maximum 
                        of 14 days. <span style={{ fontWeight: '500' }}>However, Empowered Conversations is currently running our pilot program. 
                        As a part of the pilot program, we will ask you if you consent to an anonymous survey. If you consent to this
                        survey, your phone number will be stored for a maximum of 28 days. If you do not, it will be stored for the maximum 
                        of 14 days.</span> For more information, see our <span><a href="https://empoweredconversations.org/privacypolicy" target="_blank" rel="noopener noreferrer">
                            privacy policy.
                        </a></span>
                    </p>
                </div>
                <div className="blurb">
                    <h3><b> Terms of Service: </b></h3>
                    <p>
                        At Empowered Conversations, we strive to create a culture of transparency. While we hope 
                        that providing your confidant with our training modules and resources will result in an 
                        effective conversation, we cannot predict nor guarantee the outcome of your conversation. 
                        Conversations surrounding sexual assault can be incredibly challenging, so there is a chance 
                        that your confidant may not be ready to listen effectively. For more information, please see 
                        our <span><a href="https://empoweredconversations.org/termsofservices" target="_blank" rel="noopener noreferrer">
                            terms of services.
                        </a></span>
                    </p>
                </div>
                <div className="blurb">
                    <h3><b> Disclaimer: </b></h3>
                    <p>
                        The contents of the Empowered Conversations site such as texts, graphics, images, and other 
                        materials are for informational and educational purposes only. This content is not intended 
                        to be a substitute for professional medical advice, diagnosis, or treatment. If you or anyone 
                        you know is in immediate danger, please call <b>911</b>.
                    </p>
                </div>
                <div className="checkbox">
                    <Form>
                        {/* <div key={`custom-checkbox`} className="mb-3"> */}
                            <Form.Check 
                                // custom
                                type='checkbox'
                                id={`custom-checkbox`}
                                style={{
                                    display: "inline",
                                    marginRight: "0.9rem",
                                    marginTop: "0.2rem"
                                }}
                                onClick={handleClick}
                                // label={'Agree'}
                            />
                                I have read and agree to the <span><a href="https://empoweredconversations.org/termsofservices" target="_blank" rel="noopener noreferrer">
                                    terms of services
                                </a></span> and <span><a href="https://empoweredconversations.org/privacypolicy" target="_blank" rel="noopener noreferrer">
                                    privacy policy
                                </a></span>.
                            {/* </Form.Check> */}
                        {/* </div> */}
                    </Form>
                </div>
                <div className="center-button">
                    <LinkContainer to="/getstarted/continue">
                        <button disabled={!agreed}>Continue</button>
                    </LinkContainer>
                </div>
                {/* {popup ? <PilotForm popup={popup} setPopup={setPopup} /> : null} */}
            </div>
        // </React.Fragment>
    )
};

export default FormIntroScreen;



/// original intro screen, pilot specific above
// const FormIntroScreen = () => {
//     const [agreed, setAgreed] = useState(false);

//     const handleClick = () => {
//         setAgreed(!agreed);
//     }

//     return (
//         // <React.Fragment>
//             <div className="cont">
//                 <PilotForm/>
//                 <h1> Using the Empowered Conversations Tool </h1>
//                 <div className="blurb">
//                     <h3><b> What Happens: </b></h3>
//                     <p>
//                         After you fill out our form, providing us with your chosen confidant and phone number, 
//                         we will then send a text message to the confidant that includes our training modules 
//                         and a heads-up that someone close to them would like to disclose their experience. 
//                         You will also receive a text message letting you know that the confidant message has 
//                         been delivered. Once your confidant has completed the training, you will receive a text 
//                         from us letting you know that your confidant may be ready to have a conversation. At that 
//                         point, you may initiate the conversation with your confidant whenever you feel ready.
//                     </p>
//                 </div>
//                 <div className="blurb">
//                     <h3><b> Your Privacy: </b></h3>
//                     <p>
//                         Throughout the whole process, your anonymity will remain protected. We encrypt and then 
//                         store your cell phone number and your confidant’s first name and cell phone number. This 
//                         means your information is converted into an unrecognizable code that no one can understand 
//                         before it is stored in our database. Only our engineering staff has access to the key to 
//                         decrypt the information. The encrypted data is then deleted from our system after a maximum 
//                         of 14 days. For more information, see our <span><a href="https://empoweredconversations.org/privacypolicy" target="_blank" rel="noopener noreferrer">
//                             privacy policy.
//                         </a></span>
//                     </p>
//                 </div>
//                 <div className="blurb">
//                     <h3><b> Terms of Service: </b></h3>
//                     <p>
//                         At Empowered Conversations, we strive to create a culture of transparency. While we hope 
//                         that providing your confidant with our training modules and resources will result in an 
//                         effective conversation, we cannot predict nor guarantee the outcome of your conversation. 
//                         Conversations surrounding sexual assault can be incredibly challenging, so there is a chance 
//                         that your confidant may not be ready to listen effectively. For more information, please see 
//                         our <span><a href="https://empoweredconversations.org/termsofservices" target="_blank" rel="noopener noreferrer">
//                             terms of services.
//                         </a></span>
//                     </p>
//                 </div>
//                 <div className="checkbox">
//                     <Form>
//                         {/* <div key={`custom-checkbox`} className="mb-3"> */}
//                             <Form.Check 
//                                 // custom
//                                 type='checkbox'
//                                 id={`custom-checkbox`}
//                                 style={{
//                                     display: "inline",
//                                     marginRight: "0.9rem",
//                                     marginTop: "0.2rem"
//                                 }}
//                                 onClick={handleClick}
//                                 // label={'Agree'}
//                             />
//                                 I have read and agree to the <span><a href="https://empoweredconversations.org/termsofservices" target="_blank" rel="noopener noreferrer">
//                                     terms of services
//                                 </a></span> and <span><a href="https://empoweredconversations.org/privacypolicy" target="_blank" rel="noopener noreferrer">
//                                     privacy policy
//                                 </a></span>.
//                             {/* </Form.Check> */}
//                         {/* </div> */}
//                     </Form>
//                 </div>
//                 <div className="center-button">
//                     <LinkContainer to="/getstarted/continue">
//                         <button disabled={!agreed}>Continue</button>
//                     </LinkContainer>
//                 </div>
//             </div>
//         // </React.Fragment>
//     )
// };