import React, { useState } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
// import Radio from '@mui/material/Radio';
// import RadioGroup from '@mui/material/RadioGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import FormControl from '@mui/material/FormControl';
// import FormLabel from '@mui/material/FormLabel';
import '../css/Pages.css';
import axios from 'axios';

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

const OptOutScreen = () => {
  const location = useLocation();
  const [choice, setChoice] = useState('generic')
  const [convoId, setConvoId] = useState(location.convoId)
  const [error, setError] = useState(false)
  let history = useHistory();


  const handleTextSend = (option, query) => {
    if (convoId) {
      axios
        .post(`${apiEndpoint}/bump-opt-out`, { convoId })
        .then(_ => {
          return;
        })
        .catch(err => {
          console.error(err);
          alert("An unexpected error occurred!");
          return;
        });
      axios
        .post(`${apiEndpoint}/send-opt-out-text`, { convoId, query, option })
        .then(_ => {
          return;
        })
        .catch(err => {
          console.error(err);
          alert("An unexpected error occurred!");
          return;
        });
    }
    history.push({
      pathname: '/resources',
      banner: false
    })
  }

  const handleChange = (event) => {
    setChoice(event.target.value);
  };

  const handleConvoChange = (event) => {
    setConvoId(event.target.value)
  }

  const checkConvo = async (convo) => {
    if (convo === '') {
      return;
    }
    let result = "";
    await axios
      .post(`${apiEndpoint}/convos`, { convo })
      .then(res => {
        // if (res.data.completedAt) {
        //     setTextSent(true);
        // }
        setError(false)
        result = res.data.query
      })
      .catch(err => {
        setError(true)
        // Expected error: 404 not a conversation
        if (err.response && err.response.status === 404) {
          result = false
        } else {
          // Deal with unexpected errors
          console.log(err);
          result = false
        }
      });
    return (result)
  }
  const handleSubmit = () => {
    checkConvo(convoId).then(result => {
      if (result) handleTextSend(choice, result)
      else console.log("boo")
    })
  }

  return (

    <div className="optout-container">
      <h1> Opt Out </h1>
      <h3>We at Empowered Conversations understand and appreciate that watching these videos and having conversations around sexual assault can be challenging. If you would like, please choose an option below to send a message to the survivor immediately. This decision is 100% up to you. If you opt not to send them a message at this time, we will send them the “time out” message. We admire your capability to make the decision that is best for you and want both you and the survivor to know that this decision does not impact your love or support for them.</h3>
      <h1> Text Options </h1>
      <FormControl component="fieldset" className="options">
        <TextField error={error} id="outlined-basic" 
        InputLabelProps={{style: {fontSize: 15}}}
        inputProps={{style: {fontSize: 15}}}
        label="Conversation Code" variant="outlined" onChange={handleConvoChange} className="convoid" size="large" value={convoId ? convoId : null} />
        {error ? <FormHelperText className="convoid c-error" margin="dense" error={true} >Conversation ID not found</FormHelperText> : false}
        <RadioGroup
          aria-label="opt-out"
          value={choice}
          name="radio-buttons-group"
          style={{ display: "inline-block" }}
          onChange={handleChange}
        >
            <FormControlLabel value="generic" control={<Radio />} label={<h2>Generic</h2>} />
            <p>[Name] would like us to inform you that they appreciate your strength and bravery, but for their own mental health, they are not able to complete our training modules. This is in no way a reflection of their love or support for you. You are also always welcome to send the training to another confidant using <span><a href="https://empoweredconversations.org/getstarted" target="_blank" rel="noopener noreferrer">empoweredconversations.org/getstarted</a></span>. You can find more resources at <span><a href="https://empoweredconversations.org/resources" target="_blank" rel="noopener noreferrer">empoweredconversations.org/resources</a></span> and the RAINN sexual assault hotline 1-800-656-4673 is available 24/7.</p>
            <FormControlLabel value="mental health" control={<Radio />} label={<h2>Mental Health</h2>} />
            <p>[Name] would like us to inform you that they appreciate your strength and bravery, but for their own mental health, they are not able to complete our training modules. This is in no way a reflection of their love or support for you. You are also always welcome to send the training to another confidant using <span><a href="https://empoweredconversations.org/getstarted" target="_blank" rel="noopener noreferrer">empoweredconversations.org/getstarted</a></span>. You can find more resources at <span><a href="https://empoweredconversations.org/resources" target="_blank" rel="noopener noreferrer">empoweredconversations.org/resources</a></span> and the RAINN sexual assault hotline 1-800-656-4673 is available 24/7.</p>
            <FormControlLabel value="time out" control={<Radio />} label={<h2>Time Out</h2>} />
            <p>Hi there, we wanted to let you know that [name] has not completed our training modules. There are many reasons why someone might not complete the modules, and this is in no way a reflection of their love or support for you. You are also always welcome to send the training to another confidant using <span><a href="https://empoweredconversations.org/getstarted" target="_blank" rel="noopener noreferrer">empoweredconversations.org/getstarted</a></span>. You can find more resources at <span><a href="https://empoweredconversations.org/resources" target="_blank" rel="noopener noreferrer">empoweredconversations.org/resources</a></span> and the RAINN sexual assault hotline 1-800-656-4673 is available 24/7.</p>
        </RadioGroup>
      </FormControl>
      <div className="buttondiv">
      <button className="button-1" onClick={handleSubmit} >Send Text</button>
      </div>
    </div>
  )
};

export default OptOutScreen; 