import React from 'react';
import ContactForm from '../components/ContactForm';
import '../css/AboutUs.css';
import '../css/Pages.css';
import AnonymousForm from '../components/AnonymousForm';
import firebase from "firebase/app";
import "firebase/analytics";

const ContactUsScreen = () => {
    firebase.analytics().logEvent('page_view', {
        page_path: '/contactus',
        page_title: 'Contact Us Screen'
      });

    return (
        <div className="a-container">
            <div className="mission">
                <h1> Contact Us </h1>
                <div style={{ padding: '20px 35px 0px' }}></div>
                <ContactForm />
                <h1> Submit Anonymous Feedback, Comment, or Testimonial </h1>
                <AnonymousForm />
            </div>
        </div>
    )
};

export default ContactUsScreen;