import React from 'react';
import NULogo from '../img/nu-horizontal.gif';
import '../css/Pages.css';
import '../css/AboutUs.css';

const ParticipatingSchoolsScreen = () => {
    return (
        <div className="a-container">
            <div className="mission">
                <h1> Participating Schools </h1>
                <div style={{ padding: '20px 35px 0px' }}></div>
                <a href="https://northwestern.edu" target="_blank" rel="noopener noreferrer">
                    <img src={NULogo} alt="Northwestern University" />
                </a>
            </div>
            <p> To get involved with Empowered Conversations, please email <a href="mailto:smaciag@empoweredconversations.org">smaciag@empoweredconversations.org</a>. </p>
            <p> As we grow, we will continue to partner with new schools in order to reach as many people as possible. </p>
        </div>
    )
};

export default ParticipatingSchoolsScreen;