import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import '../css/ConvoForm.css';

const validationSchema = Yup.object().shape({
    convoId: Yup.string()
        .length(7, 'Conversation code must be 7 characters long')
});

const ConvoForm = ({setConvoId, convoId, checkConvo}) => {

    return (
        <Popup 
            defaultOpen
            position="center center"
            modal
            // lockScroll
            closeOnDocumentClick={false}
            closeOnEscape={false}
        >
            {close => (
                <div className="code-container">
                    {/* <button className="close" onClick={close}>
                        &times;
                    </button> */}
                    <div className="header"> 
                        If you have received a text from us, please enter your 7 character code here:
                    </div>
                    <Formik 
                        validationSchema={validationSchema}
                        onSubmit={async (values, actions) => {
                            actions.setSubmitting(true);
                            if (values.convoId) {
                                const check = await checkConvo(values.convoId.toLowerCase());
                                if (check === true) {
                                    setConvoId(values.convoId.toLowerCase());
                                    close()
                                } else {
                                    actions.setErrors({convoId: check})
                                }
                            } else {
                                close()
                            }
                            
                        }}
                        initialValues={{ convoId: '' }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            isSubmitting,
                            values,
                            touched, 
                            errors
                        }) => (
                            <Form onSubmit={handleSubmit} className="form-container">
                                <Form.Group className="form-text">
                                    <Form.Control 
                                        type="text" 
                                        name="convoId" 
                                        placeholder="Conversation code"
                                        value={values.convoId.toLowerCase()} 
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.convoId && errors.convoId ? "text error" : "text"}
                                    />
                                    {touched.convoId && errors.convoId ? (
                                        <div className="error-message">{errors.convoId}</div>
                                        ): null}
                                </Form.Group>
                                <div className="convo-button-container">
                                    {/* FIX BUTTON COLORS PLS */}
                                    <Button className="convo-button" disabled={isSubmitting} onClick={() => { close() }} >
                                        Continue without Code
                                    </Button>  
                                    <Button className="convo-button" type="submit" disabled={isSubmitting || !values.convoId || errors.convoId} >
                                        Submit
                                    </Button> 
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            )}
        </Popup>
    )
};

export default ConvoForm;