import React, { useEffect } from 'react';
// import './App.css';
import NavBar from './components/NavBar';
import { Route, Switch, withRouter, useLocation } from 'react-router-dom';
import FormIntroScreen from './screens/FormIntroScreen';
import HomeScreen from './screens/HomeScreen';
import NotFound from './components/NotFound';
import FormScreen from './screens/FormScreen';
import ResourcesScreen from './screens/ResourcesScreen';
import ModulesIntroScreen from './screens/ModulesIntroScreen';
import ModulesScreen from './screens/ModulesScreen';
import AboutUsScreen from './screens/AboutUsScreen';
import Footer from './components/Footer';
import FAQScreen from './screens/FAQScreen';
import ParticipatingSchoolsScreen from './screens/ParticipatingSchoolsScreen';
import ContactUsScreen from './screens/ContactUsScreen';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfServices from './components/TermsofServices';
import DonateScreen from './screens/DonateScreen';
import OptOutScreen from './screens/OptOutScreen';
import IntersectionalityScreen from './screens/IntersectionalityScreen';
import SourcesScreen from './screens/SourcesScreen';
// import RedirectScreen from './screens/RedirectScreen';

// While development in progress, deployed redirect screen 
// const App = () => {
//     return (
//       <RedirectScreen />
//     )
// };

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);
  return props.children
}
const ScrollToTop = withRouter(_ScrollToTop)


//may need to change Switch --> Routes (v6 of reat-router-dom)
const App = () => {
  return (
    <React.Fragment>
      <ScrollToTop>
      <NavBar />
      <main>
        <Switch>
          <Route exact path="/home" component={HomeScreen} />
          <Route exact path="/" component={HomeScreen} />
          <Route exact path="/getstarted" component={FormIntroScreen} />
          <Route exact path="/getstarted/continue" component={FormScreen} /> 
          <Route exact path="/resources" component={ResourcesScreen} />
          <Route path="/tomodules" component={ModulesIntroScreen} />
          <Route path='/modules' component={ModulesScreen} />
          <Route path='/aboutus' component={AboutUsScreen} />
          <Route path='/faq' component={FAQScreen} />
          <Route path='/participatingschools' component={ParticipatingSchoolsScreen} />
          <Route path='/contactus' component={ContactUsScreen} />
          <Route path='/intersectionality' component={IntersectionalityScreen} />
          <Route path='/sources' component={SourcesScreen} />
          <Route path='/privacypolicy' component={PrivacyPolicy} />
          <Route path='/termsofservices' component={TermsOfServices} />
          <Route path='/donate' component={DonateScreen} />
          <Route path='/optout' component={OptOutScreen} />
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </main>
      <Footer />
      </ScrollToTop>
    </React.Fragment>
  )
};


export default App;
