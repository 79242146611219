import React from 'react';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Form from 'react-bootstrap/Form';
import '../css/Pages.css';

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

const validationSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, "Name must be at least 2 characters long")
        .max(20, "Names can't be longer than 30 characters")
        .required("Name is required"),
    email: Yup.string()
        .email('Invalid email')
        .required("Must be a valid email address"),
    message: Yup.string()
        .min(2, "Message must be at least 2 characters long")
        .required("Please enter a message")
});

const ContactForm = () => {
  const sendEmail = (values) => {
    const { name, email, message } = values;
    axios
        .post(
            `${apiEndpoint}/send-email`,
            { 
                name, 
                email,
                message
            }
        )
        .catch(error => {
            console.log('error caught in react', error)
        })
  }

//   async function handleFormSubmit(values) {
//     db.collection('emails').add({
//         name: values.name,
//         email: values.email,
//         message: values.message,
//     }).catch((error) => {
//         console.error('Error adding to firebase: ', error);
//     });
// }

  return (
      <div className="contact-form-container">
        <Formik 
            validationSchema={validationSchema}
            onSubmit={(values, {setSubmitting, resetForm}) => {
                setSubmitting(true);
                // handleFormSubmit(values);
                sendEmail(values);
                setSubmitting(false);
                resetForm();
            }}
            initialValues={{ name: '', email: '', message: '' }}
        >
        {({
            handleSubmit,
            handleChange,
            handleBlur,
            isSubmitting,
            values,
            touched, 
            errors
        }) => (
            <Form onSubmit={handleSubmit}>
                <Form.Group className="form-text">
                    <Form.Label>Your Name: </Form.Label>
                    <Form.Control 
                        type="text" 
                        name="name" 
                        placeholder="Name"
                        value={values.name} 
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={touched.name && values.name && errors.name ? "error" : null}
                    />
                    {touched.name && values.name && errors.name ? (
                        <div className="page-error-message">{errors.name}</div>
                        ): null}
                </Form.Group>
                <Form.Group className="form-text">
                    <Form.Label>Your Email Address: </Form.Label>
                    <Form.Control 
                        type="text" 
                        name="email" 
                        placeholder="Email address"
                        value={values.email} 
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={touched.email && values.email && errors.email ? "error" : null}
                    />
                    {touched.email && values.email && errors.email ? (
                        <div className="page-error-message">{errors.email}</div>
                        ): null}
                </Form.Group>
                <Form.Group className="form-message">
                    <Form.Label>Your Message: </Form.Label>
                    {/* <Form.Control 
                        type="textarea" 
                        name="message" 
                        placeholder="Message"
                        // multiline={true}
                        value={values.message} 
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={touched.message && values.message && errors.message ? "error message" : "message"}
                    /> */}
                    <textarea
                        type="text"
                        name="message"
                        placeholder="Message"
                        // multiline="true"
                        value={values.message}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={touched.message && values.message && errors.message ? "error message" : "message"}
                    />
                    {touched.message && values.message && errors.message ? (
                        <div className="page-error-message">{errors.message}</div>
                        ): null}
                </Form.Group>
                <button type="submit" className="button" disabled={isSubmitting || !values.email || !values.message || errors.message}>
                    Send Message
                </button> 
            </Form>
        )}
        </Formik>
    </div>
  )

//   return (
//     <div style={{paddingTop: '90px'}}>
//       <form onSubmit={handleSubmit}>
//         <input
//           type="text"
//           name="name"
//           placeholder="Name"
//           onChange={updateInput}
//           value={formData.name || ''}
//         />
//         <input
//           type="email"
//           name="email"
//           placeholder="Email"
//           onChange={updateInput}
//           value={formData.email || ''}
//         />
//         <textarea
//           type="text"
//           name="message"
//           placeholder="Message"
//           onChange={updateInput}
//           value={formData.message || ''}
//         ></textarea>
//         <button type="submit">Submit</button>
//       </form>
//     </div>
//   )
}

export default ContactForm;