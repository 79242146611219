import React from 'react';
import '../css/AboutUs.css';
import ProfChung from '../img/ProfChung.jpg';
import Suzanne from '../img/Suzanne.jpg';
import Infographic from '../img/Infographic.JPG';
import lindsay from '../img/lindsay.jpg';
import gwyn from '../img/gwyn.jpg';
import laura from '../img/laura.jpg';
import sarah from '../img/sarah.jpg';
import casey from '../img/casey.jpg';
import elina from '../img/elina.jpg';
import jordan from '../img/jordan.jpg';
import sky from '../img/sky.jpg';
import maddie from '../img/maddie.jpg';
import Max from '../img/Max.jpg';

const AboutUsScreen = () => {
    return (
        <div className="a-container a-row">
            <div className="mission">
                <h1> Our Mission </h1>
                <div style={{ padding: '20px 35px 0px' }}>
                    <p>We seek to promote effective, authentic, and empathetic conversations between survivors of sexual assault and their family members and friends. Our survivor-activated tool educates and prepares confidants before the conversation takes place. </p>
                    <p>The stigma of sexual assault is highly pervasive and leads to only 50% of survivors ever disclosing their assault. Our approach to creating a healthy environment for a survivor to disclose consists of a survivor-activated chat tool that provides training videos and educational modules for a family member, friend, or confidant. We believe that these tools will allow loved ones the chance to process their emotions before speaking with the survivor. When a survivor is assured that their family and friends have a better understanding of sexual assault and know best practices for how to provide support, it allows the survivor to feel more empowered in the conversation. </p>
                    <p>While we are currently focused on conversations regarding sexual assault, we will soon expand to provide educational resources to help individuals tackle difficult conversations related to other stigmatized experiences such as mental health, specifically disclosing suicidality.</p>
                </div>
            </div>
            <div className="team">
                <h1> Our Team </h1>
                <div className="row">
                <div className="col-lg-4 col-sm-6">
                        <img src={sarah} alt="Sarah Maciag" className="img" />
                        <p><b> Sarah Maciag </b> <i> (she/her/hers) </i><br></br> President <br></br>
                            {/* <a href="mailto:sarah@empoweredconversations.org"> sarah@empoweredconversations.org </a> */}
                        </p>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <img src={laura} alt="Laura Wiseman" className="img" />
                        <p><b> Laura Wiseman </b> <i> (she/her/hers) </i><br></br> Chief Technical Officer <br></br>
                            {/* <a href="mailto:laura@empoweredconversations.org"> laura@empoweredconversations.org </a> */}
                        </p>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <img src={casey} alt="Casey Delgado-Miller" className="img" />
                        <p><b> Casey Delgado-Miller </b> <i> (she/her/hers) </i><br></br> Grant Proposal Manager <br></br>
                            {/* <a href="mailto:gwyn@empoweredconversations.org"> gwyn@empoweredconversations.org </a> */}
                        </p>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-md-offset-2">
                        <img src={elina} alt="Elina Rawat" className="img" />
                        <p><b> Elina Rawat </b> <i> (she/her/hers) </i><br></br> Software Developer <br></br>
                            {/* <a href="mailto:gwyn@empoweredconversations.org"> gwyn@empoweredconversations.org </a> */}
                        </p>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <img src={gwyn} alt="Gwyn Hodges" className="img" />
                        <p><b> Gwyn Hodges </b> <i> (she/her/hers) </i><br></br> Senior Editor <br></br>
                            {/* <a href="mailto:gwyn@empoweredconversations.org"> gwyn@empoweredconversations.org </a> */}
                        </p>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <img src={jordan} alt="Jordan Zax" className="img" />
                        <p><b> Jordan Zax </b> <i> (he/him/his) </i><br></br> Senior Software Developer <br></br>
                            {/* <a href="mailto:gwyn@empoweredconversations.org"> gwyn@empoweredconversations.org </a> */}
                        </p>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <img src={lindsay} alt="Lindsay Lipschultz" className="img" />
                        <p><b> Lindsay Lipschultz </b> <i> (she/her/hers) </i><br></br> Creative Director <br></br>
                            {/* <a href="mailto:lindsay@empoweredconversations.org"> lindsay@empoweredconversations.org </a> */}
                        </p>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <img src={maddie} alt="Madeleine Vera" className="img" />
                        <p><b> Madeleine Vera </b> <i> (she/her/hers) </i><br></br> Assistant Treasurer <br></br>
                            {/* <a href="mailto:julia@empoweredconversations.org"> julia@empoweredconversations.org </a>  */}
                        </p>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-md-offset-4">
                        <img src={sky} alt="Skyler Maeso" className="img" />
                        <p><b> Skyler Maeso </b> <i> (she/her/hers) </i><br></br> Social Media Director <br></br>
                            {/* <a href="mailto:gwyn@empoweredconversations.org"> @empoweredconversations.org </a> */}
                        </p>
                    </div>
                </div>
            </div>
            <div className="board">
                <h1> Our Board </h1>
                <div className="row">
                    <div className="col-md-4">
                        <img src={ProfChung} alt="Professor / Doctor Alissa Levy Chung" className="img" />                   
                        <p><b> Professor / Doctor Alissa Levy Chung </b> <i> (she/her/hers) </i></p>
                    </div>
                    <div className="col-md-4">
                        <img src={Suzanne} alt="Suzanne Weidberg Klein" className="img" />
                        <p><b> Suzanne Weidberg Klein </b> <i> (she/her/hers) </i></p>
                    </div>
                    <div className="col-md-4">
                        <img src={Max} alt="Max Zelaitis" className="img" />
                        <p><b> Max Zelaitis </b> <i> (he/him/his) </i></p>
                    </div>
                </div>
            </div>
            <div className="tool-graphic">
                <h1> Our Tool </h1>
                <img src={Infographic} alt="Empowered Conversations Journey" />
            </div>
        </div>
    )
};

export default AboutUsScreen;