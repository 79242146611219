import React, { useState, useEffect } from 'react';
import * as d3 from 'd3';
import BubbleChart from '../components/BubbleChart';
import '../css/StatisticsScreen.css';


const IntersectionalityScreen = () => {
    // let reportedStats = [1, 1, 1, 0]

    // useEffect(() => {
    //     var svg = d3.select("#reported-graphic")
    //         .append("svg")
    //         .attr("width", '80vh')
    //         .attr("height", 400)

    //     // Add the path using this helper function
    //     reportedStats.forEach((val, index) => {
    //         svg.append('rect')
    //             .attr('x', 110*index + 100)
    //             .attr('y', 50)
    //             .attr('width', 100)
    //             .attr('height', 350)
    //             .on("mouseover", function () { d3.select(this).attr("stroke", "#000"); })
    //             .on("mouseout", function () { d3.select(this).attr("stroke", null); })
    //             .attr('fill', val === 1 ? "#1A254C" : "#C9DBFF");
    //     })

    //     d3.select("#reported-graphic")
    //         .append('h4')
    //         .text("An estimated 3 out of 4 sexual harrassment cases are never formally reported")
    
    // }, [])

    return (
        <div className="a-container mission" >
            <h1> How does identity impact trauma and healing? </h1>
            <BubbleChart />
            <p><a href="/sources">Sources</a></p>
        </div>
    );

};

export default IntersectionalityScreen;