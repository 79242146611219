import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/firestore";
import "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyDd-EMWh-TEOCWmJaVTgxBn1rs8kKcsfic",
    authDomain: "empoweredconversations-9be02.firebaseapp.com",
    databaseURL: "https://empoweredconversations-9be02.firebaseio.com",
    projectId: "empoweredconversations-9be02",
    storageBucket: "empoweredconversations-9be02.appspot.com",
    messagingSenderId: "25350143222",
    appId: "1:25350143222:web:9cf8c858139fb7b7b100d7",
    measurementId: "G-30RWB8X2LT"       
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

export { firebase };