import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import * as Yup from 'yup';
import axios from 'axios';
import Popup from 'reactjs-popup';
import '../css/Pages.css';


const CONTAINER = styled.div`
    background: white;
    height: auto; 
    width: 80%;
    margin: 4.5em auto;
    color: snow;
    -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.4);
    box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;

    label {
        color: #1a254c;
        font-size: 1.5em;
        font-weight: 400;
    }

    @media(min-width: 786px) {
        width: 60%;
    }

    h1 {
        color: #aec6c2;
        padding-top: .5em;
    }
    
    .form-group {
        margin-bottom: 2.5em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .error { 
        border: 2px solid #FF6565;
    }
    .error-message {
        color: #FF6565;
        height: 1em;
        position: absolute;
        font-size: 1.2em;
      }
`;

const MYFORM = styled(Form)`
  width: 80%;
  text-align: center;
  padding-top: 2em;
  padding-bottom: 2em;
  align-items: center;

  @media(min-width: 786px) {
    width: 50%;
  }
`;

const BUTTON = styled(Button)`
  background: #1a254c;
  border: none;
  font-size: 1.5em;
  font-weight: 400;

  &:hover {
    background: #7383a8;
  }
`;

// check on phone format
const phoneRegExp = /^[0-9]{10}$/

const validationSchema = Yup.object().shape({
    friendFirstName: Yup.string()
        .min(2, "Name must be at least 2 characters long")
        .max(20, "Names can't be longer than 20 characters")
        .required("Name is required"),
    friendPhone: Yup.string()
        .matches(phoneRegExp, 'Please enter a valid phone number')
        .required("Confidant phone number is required"),
    survivorPhone: Yup.string()
        .matches(phoneRegExp, 'Please enter a valid phone number')
        .required("Your phone number is required"),
});

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
const InfoForm = ({setFormState}) => {
    const [popUp, setPopUp] = useState({});
    const [openPopUp, setOpenPopUp] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    // async function handleClosePopup({close}) {
    //     // setSubmitting(false);
    //     setPopUp({});
    //     setOpenPopUp(false);
    //     close;
    // }
    
    async function handleFormSubmit(values) {
        // setOpenPopUp(false);
        setSubmitting(true)
        console.log(values)
        const survivorPhone = `+1${values.survivorPhone}`;
        const friendPhone = `+1${values.friendPhone}`;
        const friendName = values.friendFirstName;

        axios
            .post(`${apiEndpoint}/new-convo`, {
                survivorPhone,
                friendName,
                friendPhone
            })
            .then(res => {
                setFormState(false);
                setOpenPopUp(false);
                setPopUp({});
                setSubmitting(false);
            })
            .catch(err => {
                // Deal with unexpected errors
                console.log(err);
                alert("An unexpected error occurred!");
                return;
            });
    }

    return (
        <React.Fragment>
            <CONTAINER>
                <Formik 
                validationSchema={validationSchema}
                onSubmit={(values, {setSubmitting}) => {
                    // setSubmitting(true);
                    // handleFormSubmit(values);
                    setOpenPopUp(true);
                    setPopUp(values);
                    setSubmitting(false);
                }}
                initialValues={{
                    friendFirstName: '',
                    friendPhone: '',
                    survivorPhone: '', 
                }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        isSubmitting,
                        values,
                        touched, 
                        errors
                    }) => (
                        <MYFORM className="mx-auto" onSubmit={handleSubmit}>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="survivor-phone">
                                    <Form.Label><b>Your</b> Phone Number: </Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        name="survivorPhone" 
                                        placeholder="XXXXXXXXXX" 
                                        value={values.survivorPhone}
                                        onChange={handleChange} 
                                        onBlur={handleBlur}
                                        className={touched.survivorPhone && errors.survivorPhone ? "error" : null}
                                    />
                                    {touched.survivorPhone && errors.survivorPhone ? (
                                        <div className="page-error-message">{errors.survivorPhone}</div>
                                    ): null}
                                </Form.Group>
                                <Form.Group controlId="friend-name">
                                    <Form.Label><b>Confidant</b> First Name: </Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        name="friendFirstName" 
                                        placeholder="First Name"
                                        value={values.friendFirstName} 
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={touched.friendFirstName && errors.friendFirstName ? "error" : null}
                                    />
                                    {touched.friendFirstName && errors.friendFirstName ? (
                                        <div className="page-error-message">{errors.friendFirstName}</div>
                                    ): null}
                                </Form.Group>
                                <Form.Group controlId="friend-phone">
                                    <Form.Label><b>Confidant</b> Phone Number: </Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        name="friendPhone" 
                                        placeholder="XXXXXXXXXX" 
                                        value={values.friendPhone}
                                        onChange={handleChange} 
                                        onBlur={handleBlur}
                                        className={touched.friendPhone && errors.friendPhone ? "error" : null}
                                    />
                                    {touched.friendPhone && errors.friendPhone ? (
                                        <div className="page-error-message">{errors.friendPhone}</div>
                                    ): null}
                                </Form.Group>
                                {/* <Form.Group controlId="survivor-phone">
                                    <Form.Label>Your Phone Number: </Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        name="survivorPhone" 
                                        placeholder="XXXXXXXXXX" 
                                        value={values.survivorPhone}
                                        onChange={handleChange} 
                                        onBlur={handleBlur}
                                        className={touched.survivorPhone && errors.survivorPhone ? "error" : null}
                                    />
                                    {touched.survivorPhone && errors.survivorPhone ? (
                                        <div className="page-error-message">{errors.survivorPhone}</div>
                                    ): null}
                                </Form.Group> */}
                                <BUTTON type="submit" variant="primary" disabled={isSubmitting}>
                                    Submit
                                </BUTTON>  
                            </Form>
                        </MYFORM>
                    )}
                </Formik>
            </CONTAINER>
            {openPopUp ? <Popup 
                defaultOpen
                position="center center"
                modal
                // lockScroll
                closeOnDocumentClick={false}
                closeOnEscape={false}
            >
                {close => (
                    <div className="code-container">
                        <button className="close" onClick={() => {
                            setOpenPopUp(false);
                            close();
                        }}>
                            &times;
                        </button>
                        <div className="formValues">
                            <h2> Your Phone Number: </h2>
                            <h3> {popUp.survivorPhone }</h3>
                        </div>
                        <div className="formValues">
                            <h2>Confidant First Name: </h2>
                            <h3> {popUp.friendFirstName}</h3>
                        </div>
                        <div className="formValues">
                            <h2>Confidant Phone Number: </h2>
                            <h3> {popUp.friendPhone}</h3>
                        </div>
                        {/* <div className="header"> 
                            By exiting this will end your training and provide you with mental health resources. 
                        </div> */}
                        <button className="button-1" onClick={() => {
                                setOpenPopUp(false);
                                close();
                            }}>Edit</button>
                        <button className="button-1" disabled={submitting} onClick={() => handleFormSubmit(popUp)}>Submit</button>
                    </div>
                )}
            </Popup>
            : null}
        </React.Fragment>
    );
};

export default InfoForm;