import React, { useState } from 'react';
import ModuleVideo from '../components/ModuleVideo';
import ConvoForm from '../components/ConvoForm';
import '../css/ModulesScreen.css';
// import { LinkContainer } from 'react-router-bootstrap';
// import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useHistory } from 'react-router';
import firebase from "firebase/app";
import "firebase/analytics";
import Popup from 'reactjs-popup';
import { queryAllByAltText } from '@testing-library/react';

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

const ModulesScreen = () => {
    firebase.analytics().logEvent('page_view', {
        page_path: '/modules',
        page_title: 'Modules Screen'
      });

    const [textSent, setTextSent] = useState(false);
    const [query, setQuery] = useState('');
    const [convoId, setConvoId] = useState('');
    const [exitPopUp, setExitPopUp] = useState(false)
    let history = useHistory();

    const checkConvo = async (convo) => {
        if (convo === '') {
            return;
        }
        let result = "";
        await axios
            .post(`${apiEndpoint}/convos`, {convo})
            .then(res => {
                if (res.data.completedAt) {
                    setTextSent(true);
                }
                setQuery(res.data.query)
                result = true
            })
            .catch(err => {
                // Expected error: 404 not a conversation
                if (err.response && err.response.status === 404) {
                    result = "Sorry, we have no record of this conversation. Please still watch our module videos."
                } else {
                    // Deal with unexpected errors
                    console.log(err);
                    result = "An unexpected error occurred!"
                }
            });
            return(result)
    }

    const handleExit = () => {
        setExitPopUp(false);

        if (convoId) {
            axios
                .post(`${apiEndpoint}/bump-opt-out`, { convoId })
                .then(_ => {
                    return;
                })
                .catch(err => {
                    console.error(err);
                    alert("An unexpected error occurred!");
                    return;
                });    
        }
        history.push({
            pathname: '/resources',
            banner: false 
        })
    }

    const handleOptOut = (convoId) => {
        setExitPopUp(false)

        history.push({
            pathname: '/optout',
            convoId: convoId 
        })
    }

    return (
        <React.Fragment>
            <ConvoForm setConvoId={setConvoId} checkConvo={checkConvo} />
            <ModuleVideo convoId={convoId} query={query} textSent={textSent} />
            <p style={{ width: '72%', marginLeft: 45, marginTop: 10, textAlign: 'left' }}>
                These modules aim to provide general best practices for having a conversation with a survivor. 
                However, it is important to note, that an individual’s intersectional identities (such as race, gender, sexuality, and ability) play a role in their experience, disclosure, and recovery. 
                For more identity specific resources <span><a href="http://empoweredconversations.org/resources#ig" target="_blank" rel="noopener noreferrer">
                    click here.
                </a></span>
            </p>
            <div className="row w-50" style={{ marginLeft: '25%' }}>
                <div className="resources-button col-md-4 order-md-2">
                <button className="button-1" onClick={() => convoId ? setExitPopUp(true) : handleExit() }>
                        Exit to Resources 
                    </button>
                </div>
                <div className="self-care-button col-md-4 order-md-1">
                    <button className="button-1" onClick={() => window.open('https://www.rainn.org/articles/self-care-friends-and-family', '_blank')}>
                        Self Care
                    </button>
                </div>
            </div>
            {exitPopUp ? <Popup 
                defaultOpen
                position="center center"
                modal
                // lockScroll
                closeOnDocumentClick={false}
                closeOnEscape={false}
            >
                {close => (
                    <div className="code-container">
                        <button className="close" onClick={() => {
                            setExitPopUp(false);
                            close();
                        }}>
                            &times;
                        </button>
                        <div className="header"> 
                            If you would like to select a message to send to the survivor, please click the “opt out messages” button which will provide you with options. Otherwise, please click the “exit to resources” button which will end the modules and reroute you to resources. 
                        </div>
                        <button className="button-1" onClick={() => { setExitPopUp(false); close() }}>Back to Modules</button>
                        <button className="button-1" onClick={() => handleOptOut(convoId, query)}>Opt Out Messages</button>
                        <button className="button-1" onClick={() => handleExit()}>Exit to Resources</button>
                    </div>
                )}
            </Popup>
        : null}
        </React.Fragment>
    )
};

export default ModulesScreen;