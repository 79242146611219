import React from 'react';
import { LinkContainer } from "react-router-bootstrap";
import '../css/ModulesScreen.css';
import '../css/Pages.css';

const ModulesIntroScreen = () => {
    return (
        <div className="a-container">
            <div className="mission">
                <h1> Modules </h1>
                <h2 style={{padding: 20}}> Thank you for visiting Empowered Conversations and using our tool </h2>
                <div className="m-intro-row row h-100">
                    <div className="m-intro col">
                        <h2> If you would like to send our resources to a confidant, please go to the get started page below. </h2>
                        <div className="g-started">
                            <LinkContainer to='/getstarted'>
                                <button className="button"> Get Started </button>
                            </LinkContainer>
                        </div>
                    </div>
                    <div className="m-intro col">
                        <h2> If you received a text or would like to watch our training modules, please continue to the modules page below. </h2>
                        <div className="to-modules">
                            <LinkContainer to='/modules'>
                                <button className="button"> Modules </button>
                            </LinkContainer> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ModulesIntroScreen;