import React from 'react';
import '../css/HomeScreen.css';
import { LinkContainer } from 'react-router-bootstrap'
import infographic from '../img/Infographic.JPG';
import graphic from '../img/graphic.jpg';
import firebase from "firebase/app";
import "firebase/analytics";


const HomeScreen = () => {
    firebase.analytics().logEvent('page_view', {
        page_path: '/home',
        page_title: 'Home Screen'
      });

    // const [closed, setClosed] = useState(false)
    return (
        <div className="Home">
            {/* {!closed ? 
            <React.Fragment>
            <div className="proto-banner">
                <div>
                    <button className="close" style={{color: 'white'}} onClick={() => setClosed(true)}>
                        &times;
                    </button>
                </div>
                Due to a delay in our nonprofit filing status, we are unfortunately unable to provide our texting tool and modules at this time. However, we are still excited to share our <span><a href="/resources" style={{color: 'white'}}>resources</a></span> with you and welcome any questions at info@empoweredconversations.org.
            </div>
            <div className="pad">

            </div>
            </React.Fragment> : null} */}
            <div className="motto-banner">
                {/* <h1><i> HELPING YOU LEARN BY LISTENING AND STRENGTHEN BY SPEAKING</i></h1> */}
                <h1>HELPING YOU LEARN BY LISTENING AND STRENGTHEN BY SPEAKING</h1>
                {/* <h1>Helping You Learn by Listening and Strengthen by Speaking</h1> */}
                <h3> Promoting effective, authentic, and empathetic conversations in a safe and private way</h3>
            </div>
            <div className="graphic">
                <img src={graphic} alt="Empowered Conversations"  className="graphic-size" />
            </div>
            <div className="infographic">
                <h1> How the Tool Works </h1>
                <img src={infographic} alt="Empowered Conversations Journey" />
            </div>
            <LinkContainer to='/getstarted'>
                <div className="b-container">
                    <button className="rounded-button"> Get Started </button>
                </div>
            </LinkContainer>
        </div>
    );
};

export default HomeScreen;