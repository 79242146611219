import React from 'react'; 
import '../css/StatisticsScreen.css';

const SourcesScreen = () => {
    return (
        <div className="sources">
            <h2> Intersectionality Sources </h2>
            <ol>
                <li>Sexual abuse &amp; assault of boys &amp; men: Confidential support for men. 1in6. (2020, June 12). <a href="https://1in6.org/">https://1in6.org/</a>.</li>
                <li>Statistics on violence against api women. Asian Pacific Institute on Gender Based Violence Website. (n.d.). <a href="https://www.api-gbv.org/about-gbv/statistics-violence-against-api-women/">https://www.api-gbv.org/about-gbv/statistics-violence-against-api-women/</a>.</li>
                <li>Sexual assault and the LGBTQ Community. HRC. (n.d.). <a href="https://www.hrc.org/resources/sexual-assault-and-the-lgbt-community">https://www.hrc.org/resources/sexual-assault-and-the-lgbt-community</a>.</li>
                <li>National organization of Asians and Pacific ISLANDERS ENDING sexual violence. NAPIESV. (n.d.). <a href="https://napiesv.org/">https://napiesv.org/</a>.</li>
                <li>Children and teens: Statistics. RAINN. (n.d.). <a href="https://www.rainn.org/statistics/children-and-teens">https://www.rainn.org/statistics/children-and-teens</a>.</li>
                <li>Victims of sexual violence: Statistics. RAINN. (n.d.). <a href="https://www.rainn.org/statistics/victims-sexual-violence">https://www.rainn.org/statistics/victims-sexual-violence</a>.</li>
                <li>Sexual abuse of people with disabilities. RAINN. (n.d.). <a href="https://www.rainn.org/articles/sexual-abuse-people-disabilities">https://www.rainn.org/articles/sexual-abuse-people-disabilities</a>.</li>
                <li>Barriers to reporting in indigenous communities. RAINN. (2019, November 1). <a href="https://www.rainn.org/news/barriers-reporting-indigenous-communities">https://www.rainn.org/news/barriers-reporting-indigenous-communities</a>.</li>
                <li>Sexual assault of men and boys. RAINN. (n.d.). <a href="https://www.rainn.org/articles/sexual-assault-men-and-boys">https://www.rainn.org/articles/sexual-assault-men-and-boys</a>.</li>
                <li>Sigurvinsdottir, R., &amp; Ullman, S. E. (2015). Sexual orientation, race, and trauma as predictors of sexual assault recovery. Journal of Family Violence, 31(7), 913–921. <a href="https://doi.org/10.1007/s10896-015-9793-8">https://doi.org/10.1007/s10896-015-9793-8</a></li>
                <li>Tillman, S., Bryant-Davis, T., Smith, K., &amp; Marks, A. (2010). Shattering silence: Exploring barriers to disclosure for african american sexual assault survivors. Trauma, Violence, &amp; Abuse, 11(2), 59–70. <a href="https://doi.org/10.1177/1524838010363717">https://doi.org/10.1177/1524838010363717</a></li>
                <li>African-American Women and Sexual Assault. (2015). Maryland Coalition Against Sexual Assault.</li>
                <li>Black survivors and sexual trauma. TIME'S UP Foundation. (2021, July 14). <a href="https://timesupfoundation.org/black-survivors-and-sexual-trauma/">https://timesupfoundation.org/black-survivors-and-sexual-trauma/</a>.</li>
                <li>Watson, Julie. 2006. Women Risk Rape, Death in U.S. Journey. NewYork, NY: Associated Press.</li>
                <li>American Association of University Women Educational Foundation. 2001. Hostile Hallways: Bullying, Teasing, and Sexual Harassment in Schools. NewYork, NY: American Association of University Women Educational Foundation.</li>
                <li>Vilissa Thompson, N. E. (2021, February 12). Sexual violence and the disability community. Center for American Progress. <a href="https://www.americanprogress.org/issues/disability/news/2021/02/12/495746/sexual-violence-disability-community/">https://www.americanprogress.org/issues/disability/news/2021/02/12/495746/sexual-violence-disability-community/</a>.</li>
                <li>"Bergen, R.K. 1996. Wife Rape: Understanding the Response of Survivors and Service Providers. Thousand Oaks, CA: Sage Publications."</li>
                <li>“6 Things to Know: Sexual Violence in the Hispanic and Latin@ Community.” Cleveland Rape Crisis Center , 1 July 2021, <a href="https://clevelandrapecrisis.org/services/servicios/sexual-violence-latina-hispanic/">https://clevelandrapecrisis.org/services/servicios/sexual-violence-latina-hispanic/</a>. </li>
                <li>“Sexual Violence in South Asia: Legal and Other Barriers to Justice for Survivors.” Equality Now, 20 Apr. 2021, <a href="https://www.equalitynow.org/sexualviolencesouthasia">https://www.equalitynow.org/sexualviolencesouthasia</a>. </li>
                <li>"Breaking Barriers: Supporting Survivors of Sexual Assault from Immigrant Communities." California Coalition Against Sexual Assault , 2010, <a href="https://evawintl.org/wp-content/uploads/Module-13_Breaking-Barriers.pdf">https://evawintl.org/wp-content/uploads/Module-13_Breaking-Barriers.pdf</a>. </li>
                <li>“Ending Violence against Native Women.” Ending Violence Against Native Women , Indian Law Resource Center, 2021, <a href="https://indianlaw.org/issue/ending-violence-against-native-women">https://indianlaw.org/issue/ending-violence-against-native-women</a>. </li>
                <li>“Challenges for Immigrant Women + Children.” KAN-WIN: Empowering Women in the Asian American Community , 2021, <a href="http://www.kanwin.org/challenges/">http://www.kanwin.org/challenges/</a>. </li>
                <li>“Our Facts.” NAPIESV: National Organization of Asians and Pacific Islanders Ending Sexual Violence, 2021, <a href="https://napiesv.org/">https://napiesv.org/</a>. </li>
                <li>“Standing As Relatives With Indigenous Survivors and Advocates for Sexual Assault Awareness Month.” National Indigenous Women's Resource Center , 1 Apr. 2021, <a href="https://www.niwrc.org/news/standing-relatives-indigenous-survivors-and-advocates-sexual-assault-awareness-month">https://www.niwrc.org/news/standing-relatives-indigenous-survivors-and-advocates-sexual-assault-awareness-month</a>. </li>
                <li>Shapiro, Joseph. “The Sexual Assault Epidemic No One Talks About.” NPR, 8 Jan. 2018, <a href="https://www.npr.org/2018/01/08/570224090/the-sexual-assault-epidemic-no-one-talks-about">https://www.npr.org/2018/01/08/570224090/the-sexual-assault-epidemic-no-one-talks-about</a>. </li>
                <li>"Help Exists Fact Sheet: Latinas and Sexual Violence." Office for Victims of Crime, <a href="https://ovc.ojp.gov/sites/g/files/xyckuh226/files/pubs/existeayuda/tools/pdf/factsheet_eng.pdf">https://ovc.ojp.gov/sites/g/files/xyckuh226/files/pubs/existeayuda/tools/pdf/factsheet_eng.pdf</a>. </li>
                <li>“Domestic and Sexual Violence.” Tahirih Justice Center, 6 Apr. 2021, <a href="https://www.tahirih.org/what-we-do/policy-advocacy/domestic-and-sexual-violence/">https://www.tahirih.org/what-we-do/policy-advocacy/domestic-and-sexual-violence/</a>. </li>
                <li>“Disabled Women &amp; Sexual Violence .” The Disability Community and Sexual Violence, End the Criminalization of Trauma, May 2018, <a href="https://now.org/wp-content/uploads/2018/05/Disabled-Women-Sexual-Violence-4.pdf">https://now.org/wp-content/uploads/2018/05/Disabled-Women-Sexual-Violence-4.pdf</a>. </li>
                <li>Thomas, Erin. Domestic Violence and Sexual Assault in the Pacific Islander Community. Asian/Pacific Islander Domestic Violence Resource Project, 2017, <a href="https://dvrp.org/wp-content/uploads/2017/05/GBV-in-the-PI-Community.pdf">https://dvrp.org/wp-content/uploads/2017/05/GBV-in-the-PI-Community.pdf</a>.</li>
            </ol>
        </div>
    )
};

export default SourcesScreen;