import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Form from 'react-bootstrap/Form';
import '../css/Pages.css';
import { firebase } from '../firebase';

const db = firebase.firestore();

const validationSchema = Yup.object().shape({
    anonComment: Yup.string()
        .min(2, 'Comment must be at least 2 characters')
        .required('Please enter a comment')
});

const AnonymousForm = () => {

    async function handleFormSubmit(values) {
        db.collection('comments').add({
            comment: values.anonComment,
        }).catch((error) => {
            console.error('Error adding to firebase: ', error);
        });
    }

    return (
        <div className="anon-form-container">
            <Formik 
                validationSchema={validationSchema}
                onSubmit={(values, {setSubmitting, resetForm}) => {
                    setSubmitting(true);
                    handleFormSubmit(values);
                    setSubmitting(false);
                    resetForm();
                }}
                initialValues={{ anonComment: '' }}
            >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    isSubmitting,
                    values,
                    touched, 
                    errors
                }) => (
                    <Form onSubmit={handleSubmit} className="anon-form" inline>
                        <Form.Group className="anon-text">
                            <Form.Control 
                                type="text" 
                                name="anonComment" 
                                placeholder="Comment"
                                value={values.anonComment} 
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={touched.anonComment && errors.anonComment ? "error comment" : "comment"}
                            />
                            {touched.anonComment && errors.anonComment ? (
                                <div className="page-error-message">{errors.anonComment}</div>
                                ): null}
                        </Form.Group>
                        {/* <div className="convo-button-container"> */}
                            <button className="button smler" type="submit" disabled={isSubmitting || !values.anonComment || errors.anonComment} >
                                Submit
                            </button> 
                        {/* </div> */}
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default AnonymousForm;