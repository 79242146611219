import React, { useState, useEffect } from 'react';
import InfoForm from '../components/InfoForm';
// import ResourcesScreen from './ResourcesScreen';
import { useHistory } from 'react-router';
import firebase from "firebase/app";
import "firebase/analytics";

const FormScreen = () => {
    firebase.analytics().logEvent('page_view', {
        page_path: '/getstarted/continue',
        page_title: 'Form Screen'
      });

    const [formState, setFormState] = useState(true);
    let history = useHistory();

    // async function handleFormSubmit(values) {
    //     db.collection('survivors').add({
    //         name: values.friendFirstName,
    //         phone: values.friendPhone,
    //         survivorphone: values.survivorPhone
    //     }).catch((error) => {
    //         console.error('Error adding to firebase: ', error);
    //     });
    //     // send text in here
    //     setFormState(!formState);
    // }

    useEffect(() => {
        if (!formState) {
            history.push({
                pathname: '/resources',
                banner: "form" 
            })
        }
    }, [history, formState])

    // if (formState) {
        return (
            <div style={{paddingTop: '4.5em'}}>
                <React.Fragment>
                    <h1 style={{textAlign: 'center'}}>
                        Submit Confidant Information
                    </h1>
                    <InfoForm setFormState={setFormState} />
                    {/* <InfoForm /> */}
                </React.Fragment>
            </div>
        );
    // } else {
    //     return (
    //         <ResourcesScreen banner={true} />
    //     );
    // };
};

export default FormScreen