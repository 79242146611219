import React from "react";
import Card from "react-bootstrap/Card";
import '../css/Pages.css';

const PrivacyPolicy = () => {
  return (
    <Card className="terms-card">
      <Card.Header>
        <h6>Empowered Conversations</h6>
        <h2>Privacy Policy</h2>
      </Card.Header>
      <Card.Body>
        <p>
            Empowered Conversations seeks to promote effective, authentic, and empathetic conversations between survivors of sexual assault and their family members and friends. Our survivor-activated tool educates and prepares confidants before the conversation takes place. When a survivor is ready to disclose their sexual assault to a confidant, they submit their cell phone number as well as the first name and cell phone number of the confidant. The confidant then receives a text message from Empowered Conversations that informs them that someone they care about is asking for their support, and provides them with a link to a trauma-informed information on sexual assault, how best to respond, and what to avoid so as not to re-trigger the survivor. Once the confidant has viewed the information, we automatically send a text to the survivor to let them know.
        </p>
        <p>
            This Privacy Policy describes what information we collect and use when you visit our website, use the text messaging service, or have been designated as a confidant by a survivor, and how we collect that information, and why we process that information.

        </p>
        <p>
            From time to time, we may update this Privacy Policy. If you continue to use our website and service after the effective date of any change, then your access and use will be deemed an acceptance of our revised Privacy Policy. Any revised Privacy Policy supersedes all previous Privacy Policies.

        </p>
        <div className="my-4 ml-4">
          <a href="#infoCollect">Information We Collect</a>
          <a href="#visitWebsite" className="indent">
            You visit our website
          </a>
          <a href="#useTextTool" className="indent">
            You use the text messaging tool
          </a>
          <a href="#designatedconfidant" className="indent">
            You are designated as a confidant by a survivor
          </a>
          <a href="#contactEmail" className="indent">
            You contact us via email
          </a>
          <a href="#respondSurvey" className="indent">
            You respond to a survey
          </a>
          <a href="#whyUseInfo">Why We Use Your Information</a>
          <a href="#provideServices" className="indent">
            To provide our services and measure our impact
          </a>
          <a href="#complyLegalReqs" className="indent">
            To comply with legal requirements
          </a>
          <a href="#whoAccess">Who Has Access to Your Information</a>
          <a href="#serviceProviders" className="indent">
            Our service providers
          </a>
          <a href="#security" className="indent">
            Security
          </a>
          <a href="#legalProcess" className="indent">
            Legal process
          </a>
          <a href="#questions">Questions About Our Privacy Policy</a>
        </div>
        <h3><span id="infoCollect"></span>Information We Collect</h3>
        <h5><span id="visitWebsite"></span>You visit our website</h5>
        <p>
          We may collect and store the following information from visitors of
          our website:
        </p>
        <ul>
          <li>
            Your IP address (automatically assigned to the computer that you are
            using by your internet service provider)
          </li>
          <li>
            Information from your device and browser (e.g. device type,
            operating system, screen resolution, language, web browser type and
            version, and language setting of your browser)
          </li>
          <li>
            The last website you visited before clicking to our website, or
            whether you are clicking through to our website via a link we sent
            you us via text message
          </li>
          <li>
            The parts of our website you visit (URL of the specific website
            pages), features you use, including whether you have submitted your
            contact information or a confidant contact information, and whether
            you have viewed or downloaded any particular video or other content
          </li>
          <li>
            The exact time when you visited each of our website pages or took
            actions within the page
          </li>
          <li>
            An approximate geographic location, including information like
            country, city, and geographic coordinates, calculated based on your
            IP address or your mobile device’s general geographic location.
          </li>
        </ul>
        <p>
          We use analytics tools such as Google Analytics to help us collect and
          track this information. We do not combine the information generated
          through the use of Google Analytics with any other information you may
          provide to us. Information collected by Google Analytics does not
          directly identify you. Google Analytics collects the IP address
          assigned to you when you visit the site. They also place a persistent
          cookie on your web browser to identify you as a unique user the next
          time you visit the site, and recognize you when you visit other sites
          that also use Google Analytics. Google collects and stores this
          information even if the "Do Not Track" function of your browser is
          turned on. Google's use and sharing of information collected via our
          website is governed by the{" "}
          <a
            href="https://marketingplatform.google.com/about/analytics/terms/us/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ display: "inline" }}
          >
            Google Analytics Terms of Use
          </a>
          . To see an overview of privacy at Google and how to opt out of
          certain Google practices, visit{" "}
          <a
            href="https://policies.google.com/privacy?hl=en"
            target="_blank"
            rel="noopener noreferrer"
            style={{ display: "inline" }}
          >
            Google’s Privacy Policy
          </a>
          . You can prevent your data from being collected by Google Analytics
          by downloading the Google Analytics opt-out browser add-on here. This
          is generally done via a new a cookie placed on your browser indicating
          your intent to opt-out. If you get a new device, install a new
          browser, erase, or otherwise alter your browser cookies, you may clear
          the opt-out cookie and no longer be subject to the Google Analytics
          opt-out.
        </p>
        <p>
          We use third party services to enable certain features and
          functionalities such as those that enable videos to be played on our
          website. Such third parties may also place cookies on your browser.
        </p>
        <p>
          Your browser should allow you to change your cookie settings to block
          third-party cookies (which are those set by third-party companies
          collecting information on websites operated by other companies), block
          all cookies (including the ones we use to collect activity information
          about our users), or block specific cookies. For more information to
          your specific browser, you can check out these links.
        </p>
        <ul>
          <li>
            <a
              href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Cookie settings in Microsoft Edge
            </a>
          </li>
          <li>
            <a
              href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies"
              target="_blank"
              rel="noopener noreferrer"
            >
              Cookie settings in Internet Explorer
            </a>
          </li>
          <li>
            <a
              href="http://support.mozilla.com/en-US/kb/Cookies"
              target="_blank"
              rel="noopener noreferrer"
            >
              Cookie settings in Firefox
            </a>
          </li>
          <li>
            <a
              href="http://www.google.com/support/chrome/bin/answer.py?hl=en&answer=95647"
              target="_blank"
              rel="noopener noreferrer"
            >
              Cookie settings in Chrome
            </a>
          </li>
          <li>
            <a
              href="http://support.apple.com/kb/PH5042"
              target="_blank"
              rel="noopener noreferrer"
            >
              Cookie settings in Safari web
            </a>
          </li>
        </ul>
        <h5><span id="useTextTool"></span>You use the text messaging tool</h5>
        <p>
        To use the tool and instruct us to send a message to the confidant to prepare them for a conversation about your assault, you must provide your cell phone number and the first name and cell phone number of the confidant you designate. We do not share your cell phone number with the confidant. Our message to the confidant only refers to “someone you care about” and that person needing their support, without disclosing any identifying information about you.

        </p>
        <p>
        We use your cell phone number only to send you a notification once your confidant has received our message and once viewed the video content provided in our message or after 14 days. We do not use your cell phone number for any other purpose.
        </p>
        <p>
        We encrypt and store your cell phone information in our databases for a maximum of 14 days. If the confidant views the video content provided in our message within less than 14 days of us sending the message, we notify you immediately and delete your cell phone  information from our records promptly after the text message is sent to you. If the confidant has not viewed the content we provide in our message within 14 days of us sending the message, we permanently delete from our databases your contact information within 24 hours after the 14th day period has expired.
        </p>
        <p>
        We also store permanently in our systems 1) the time the message to the confidant was created and sent, 2) the time the confidant completed the modules. None of this information identifies you or the confidant you designate.
        </p>
        <p><b>
        In the Northwestern University pilot program, we will send you an additional message asking you for consent to receiving a survey. If you consent, we will store your cell phone number for a maximum of 28 days, and send you an additional anonymous survey. This will override the aforementioned 14 day policy. If you do not consent, we will continue with the 14 day policy described above.
        </b></p>
        <h5><span id="designatedconfidant"></span>
          You are designated as a confidant by a survivor
        </h5>
        <p>
          A survivor of sexual assault among your friends and family may
          designate you to receive a message from us informing you that someone
          you care about is asking for your support and linking to content to
          educate and prepare you for a conversation about sexual assault. In
          such case, the survivor will provide us your first name and cell phone
          number.
        </p>
        <p>
          We use this information only to send you the message and links to
          content as instructed by the survivor. We do not store your cell phone
          number in our systems and we do not use your cell phone number for any
          other purpose.
        </p>
        <p>
          We encrypt and store your first name and phone number with a code that ties you to the survivor that designated you. We do this so that, when you view the information we send you, we can notify the survivor that you have viewed the content. We keep this information in our databases for a maximum of 14 days. If you receive our message and view the video content within less than 14 days of us sending the message to you, then we delete your information from our records promptly after notification to the survivor. If you have not viewed the content we provide through our message within 14 days after we send the message, we permanently delete your name and phone number from our databases.
        </p>
        <p><b>
        In the Northwestern University pilot program, we will send you an additional message asking you for consent to receiving a survey. If you consent, we will store your cell phone number for a maximum of 28 days, and send you an additional anonymous survey. This will override the aforementioned 14 day policy. If you do not consent, we will continue with the 14 day policy described above.
        </b></p>
        <h5><span id="contactEmail"></span>You contact us via email</h5>
        <p>
          You may contact us via email to leave a testimonial, give us feedback,
          ask us a question or report a problem. When you do, we collect and
          store your email address and the content of your message, as well as
          the content of any response we provide. Because email communications
          are not always secure, and because we tie your messages to your email
          address, please do not include sensitive information in your messages
          to us.
        </p>
        <p>
          We will not use your email address for any purpose other than
          responding to your message, unless specifically instructed by you. We
          will not spam you or share your email address with any third party.
        </p>
        <h5><span id="respondSurvey"></span>You respond to a survey </h5>
        <p>
          To measure the impact of our service and determine whether our tool is
          indeed helping survivors and their allies, we may ask our users to
          respond to survey questions. All survey participation is voluntary and
          not required. All survey responses and other data will be anonymous
          and will not identify survivors or confidants.
        </p>
        <h3><span id="whyUseInfo"></span>Why We Use Your Information</h3>
        <h5><span id="provideServices"></span>
          To provide our services and measure our impact
        </h5>
        <p>
          Our service aims to support survivors by educating and preparing the
          person they will disclose to before the conversation takes place. All
          user information is collected and used to operate our website and to
          provide our services with that goal in mind.
        </p>
        <p>
          We collect visitor data to ensure that our website functions properly
          and is optimized for your computer or device, to facilitate the
          service’s technical functioning, including security, troubleshooting,
          and resolving issues. We also use visitor and user activity data, as
          well as survey data to measure our impact and to determine if we
          should make changes to features and content of our website and
          service. 
        </p>
        <h5><span id="complyLegalReqs"></span>To comply with legal requirements</h5>
        <p>
          Additionally, we store usage data for audits, and to verify that our
          internal processes function as intended and are compliant with legal,
          regulatory, or contractual requirements.
        </p>
        <h3><span id="whoAccess"></span>Who Has Access to Your Information</h3>
        <h5><span id="serviceProviders"></span>Our service providers</h5>
        <p>
          Like all other websites and mobile services, we can not do it all
          alone. We work with vendors and service providers to help us provide
          the service.
        </p>
        <p>
          Our text messaging service provider Twilio receives your cell phone
          number and the cell phone number of the confidant you designate solely
          to facilitate the sending of the message(s) to the confidant and sending
          our confirmations to you. Twilio uses these numbers in real time and 
          automatically creates a receipt of the text conversation. Our system 
          deletes this receipt with any identifiable information within 60 minutes 
          of creation.  You can find more information on how Twilio processes your 
          cell phone data on our behalf{" "}
          <a
            href="https://www.twilio.com/legal/privacy#how-twilio-processes-your-end-users-personal-information"
            target="_blank"
            rel="noopener noreferrer"
            style={{ display: "inline" }}
          >
            here
          </a>
          .
        </p>
        <p>
          We share non-identifying data about our website visitors and users
          with the service providers listed below.
        </p>
        <ul>
          <li>
            We use cloud-based data hosting providers to host the user data we
            collect and store.
          </li>
          <li>
            We use vendors to help with our website infrastructure and to
            facilitate your viewing of video content on our website.
          </li>
          <li>
            We use analytics services to help us understand how our users use
            our service, so we can improve them, or develop other tools,
            features or new services.
          </li>
          <li>
            We may use a customer relationship management software to keep track
            of potential partners interested in offering our service to their
            community.
          </li>
          <li>
            We may use customer support or email software to help us receive and
            answer your messages.
          </li>
        </ul>
        <h5><span id="security"></span>Security</h5>
        <p>
          At Empowered Conversations, we understand that protecting the privacy
          of survivors is important. We want survivors to retain agency over
          decisions as to what information they disclose and to whom they
          disclose it. That is why we protect your information in two main ways:
        </p>
        <ol>
          <li>
            Encryption: we encrypt and then store your cell phone number and your
            confidant’s first name and cell phone number, which means your
            information is converted into an unrecognizable code that no one can
            understand. Only our engineering staff has access to the key to
            decrypt the information. If our systems were hacked into, your cell phone number would not be visible or recognizable to
            the hacker.
          </li>
          <li>
            Short data retention of identifying data: We made the decision to
            store first name and cell phone information for a maximum of 14 days.
            This limits the ability for any undesired third party to access that
            information through our systems or any misuse of the information.
          </li>
        </ol>
        <p>
          We also use standard security measures to protect access to our
          databases and systems and to prevent unlawful use of our website.
          Unfortunately, no internet-enabled data transmission or storage system
          can be guaranteed to be 100% secure. If you have reason to believe
          that your interaction with us is no longer secure (for example, if you
          feel that the security of any of the information you submitted has
          been compromised), please immediately
            contact us at info@empoweredconversations.org
          .
        </p>
        <h5><span id="legalProcess"></span>Legal process</h5>
        <p>
          In the event we receive a subpoena or court-ordered request for user
          information, we may have to provide information and data you submitted
          to us (including your cell phone number, and the first
          name and cell phone number of the confidant). It is possible that a prosecutor, a
          perpetrator’s attorney, or parties involved in a legal proceeding
          request information about your activity on our website and data you
          provided to us. This is why we only store cell phone information for a
          maximum of 14 days. Once information has been removed from our
          databases, we cannot retrieve it and it is no longer discoverable via
          legal processes. We may have visitor data that is tied to your browser,
          which may be indirectly tied to your IP address. In some cases, you
          can be identified by your internet service provider based on your IP
          address.
        </p>
        <h3><span id="questions"></span>Questions About Our Privacy Policy</h3>
        <p>
          If you have any questions about how we process and handle the
          information you submit through our service, please don’t hesitate to
            contact us at info@empoweredconversations.org
          .
        </p>
      </Card.Body>
    </Card>
  );
};

export default PrivacyPolicy;
