import React, { useState, useEffect } from 'react';
import * as d3 from 'd3';


const BubbleChart = () => {
    const [data, setData] = useState(null);
    // const [titleView, setTitleView] = useState(true);
    const [currText, setCurrText] = useState();
    let colors = ["#C9DBFF", "#AEC6C2", "#a66d89", "#a66d89", "#AEC6C2", "#C9DBFF", "#C9DBFF", "#AEC6C2", "#a66d89"];
    let longquotes = [
        "\"African American sexual assault survivors are less likely to seek help from dominant society because experiences with oppression, both intergenerational and societal trauma, require that they protect themselves from mistreatment by establishing psychological and social boundaries.\" 11",
        "\"For every black woman who reports rape, at least 15 do not report. (However, two-thirds of survivors eventually disclose sexual assault to informal systems, usually family, friends, or romantic partners)\” 13",
    ]
    let mediumlong = [
        "\"The myths people believe about the sexuality and autonomy of disabled people fuel these assaults. These myths include stereotyping all disabled people as asexual, believing a disability means an inability to participate equally in an intimate relationship, and assuming that disabled people cannot control their urges, among many others.\" 16",
        "\"In some Asian communities, violations can include being forced to watch and imitate pornography, denying the right to choose or express a different sexual orientation, forced marriage, child marriage, marital rape, ‘corrective’ rape of lesbians, body modification and humiliation, cyber-stalking, mass rape in conflict zones, and more.\" 2",
        "\"It is estimated that as many as 40% of women with disabilities experience sexual assault or physical violence in their lifetimes and that more than 90% of all people with developmental disabilities will experience sexual assault.\" 28",
        "\"Survivors of child sexual abuse are about 4 times more likely than non-victims to develop symptoms of drug abuse.\" 5",
        "\"For the increasing numbers of women who make the journey across the Mexico-U.S. border, rape has become so prevalent that many women take birth control pills or get shots before setting out to ensure that they won’t get pregnant.\" 14",
        "\"Among men with documented histories of sexual abuse, only 16% of those men consider themselves to have been sexually abused. Among women with documented histories of sexual abuse, 64% of those women consider themselves to have been sexually abused.\" 1",
        "\"21% of TGQN (transgender, genderqueer, noncomforming) college students have been sexually assualted, compared to 18% of non-TGQN females, and 4% of non-TGQN males.\" 6",
        "\"44 percent of lesbians and 61 percent of bisexual women experience rape, physical violence, or stalking by an intimate partner, compared to 35 percent of straight women.\" 3"
    ]
    let otherlong = [
        "\"Black bisexual college women reported higher rates of sexual assault (13.2 %) than Black heterosexual women.\" 10",
        "\"Lesbian and bisexual women of color are also at elevated risk for recovery difficulties following victimization.\" 10",
        "\"African American girls and women 12 years and older experienced higher rates of rape and sexual assault than White, Asian, and Latino girls and women.\" 12",
        "\"For black women, the combined and compounded effects of sexism and racism can heighten depressive and PTSD symptoms.\" 13",
        "\"Prosecutors filed charges in 75 percent of the cases in which a white woman was attacked, but when the victim was a black woman, prosecutors filed charges just 34 percent of the time.\" 13",
        "\"Concerns about ‘betraying’ one’s race when the perpetrator is also Black contribute to a lower likelihood that Black women survivors will seek out treatment.\" 13",
        "\"Self-blame, stereotypical images of African American female sexuality, as well as a cultural mandate to protect African American male perpetrators from actual and perceived unfair treatment in the criminal justice system are additional potential barriers to help seeking behaviors for victims of sexual assault.\" 10",
        "\"Barriers to disclosure included inappropriate or inadequate sexuality socialization that early on may have prevented African American women’s ability to recognize sexual assault as a form of abuse.\" 10",
    ]

    var margin = { top: 60, right: 30, bottom: 30, left: 30 },
        width = 1400 - margin.left - margin.right,
        height = 1300 - margin.top - margin.bottom;

    // console.log(data)
    function wrap(text, curr_width, title) {

        text.each(function (t) {
            // If no title, zooming out. No need to wrap
            if (!title) return
            // If node already wrapped, return
            if (t.wrapped) return

            // text.each() iterates over ALL TEXT FIELDS, including bubble titles.
            //  Only need to wrap if parent of current node is the title of the bubble
            //  grouping we zoomed into. (Otherwise, t belongs to a different group or is a grouping title)
            if (t.parent && t.parent.data[0] === title) {
                var text = d3.select(this),
                    words = text.text().split(" ").reverse(),
                    word,
                    line = [],
                    lineNumber = 0,
                    lineHeight = 1.1, // ems
                    x = text.attr("x") !== null ? text.attr("x") : 0,
                    y = text.attr("y") !== null ? text.attr("y") : 0,
                    dy = text.attr("dy") !== null ? parseFloat(text.attr("dy")) : 0 - words.length / 12,
                    tspan = text.text(null)
                        .append("tspan")
                        .attr("x", x)
                        .attr("y", y)
                        .attr("dy", dy + "em");

                // set wrapped to true for now, may set to false if getComputedTextLength() returns 0
                t.wrapped = true
                word = words.pop()
                while (word) {
                    line.push(word);
                    tspan.text(line.join(" "));

                    // Calculate current line length
                    var line_len = tspan.node().getComputedTextLength()
                    
                    // getComputedTexLength returns 0 if it fails
                    // so we set t.wrapped to false and try again on the next zoom
                    // There is a probably a better way to handle this
                    if (line_len === 0) {
                        t.wrapped=false
                        console.log("Wrapping failed, zoom out and zooom back in")
                    }
                    if (line_len >= curr_width) {
                        // if (wordCount++ >= width) {
                        line.pop();
                        tspan.text(line.join(" "));
                        line = [word];
                        tspan = text.append("tspan")
                            .attr("x", x)
                            .attr("y", y)
                            .attr("dy", ++lineNumber * lineHeight + dy + "em")
                            .text(word);
                    }
                    word = words.pop()
                }
                lineNumber = 0;
                tspan = null;
                words = [];
                
            }
        });
    }

    useEffect(() => {
        d3.csv(`Intersectionality.csv`).then((d) => {
            // d3.csv(`/IdentityStatistics/${fileName}.csv`).then((d) => {
            setData(d);
        });
        return () => undefined;
    }, []);

    useEffect(() => {
        if (currText) {
            // console.log(currText)
            wrap(d3.selectAll("text"), d3.selectAll("circle").attr("r") / 2, currText.data[0])
        }
    }, [currText])

    useEffect(() => {
        if (data) {

            let root = d3.hierarchy(d3.rollup(data, d => d.length, d => d.Community, d => d.Quote))

            // console.log('first: ', root)
            root.sum(([quote, value]) => longquotes.includes(quote) ? 3 : otherlong.includes(quote) ? 2.1 : mediumlong.includes(quote) ? 1.7 : value)
                .sort((a, b) => b.value - a.value)


            const pack = () => d3.pack()
                .size([width, height])
                .padding(1)
                (root)

            root = pack(data);
            let focus = root;
            let view;

            // create the svg container
            // d3.select(`#${fileName}`)
            //     .selectAll('h3')
            //     .data(titleData).enter()
            //     .append("h3")
            //         // .attr('class', 'stats-title')
            //         .text(d => titleData[0])

            var svg = d3.select(`#Intersectionality`)
                .append("svg")
                .attr("viewBox", `-${(width + margin.left) / 2} -${(height + margin.bottom) / 2} ${width + margin.right} ${height + margin.top}`)
                // .style("margin", "0 -14px")
                .style("cursor", "pointer")
                .on("click", (event) => zoom(event, root))

            const node = svg.append("g")
                .attr("fill", "#ccc")
                .attr("id", "int-circles")
                .selectAll("circle")
                .data(root.descendants())
                .join("circle")
                // .attr("transform", d => `translate(${d.x*1.5},${d.y})`)
                .attr("dx", d => d.x)
                .attr("dy", d => d.y)
                .attr("r", d => d.r)
                // .attr("fill", d => d === root ? "none" : fileName === "AsianCommunity" ? "#AEC6C2" : fileName === "IndigenousCommunity" ? "#a66d89" : colors[(d.value % 4)])
                .style("fill", d => d === root ? "none" : d.depth === 1 ? "#ebf2ff" : colors[d.parent.parent.children.indexOf(d.parent)])
                .attr("opacity", d => d === root ? 0 : 1)
                // .attr("pointer-events", d => !d.children ? "none" : focus === root && d.depth === 1 ? null : d.parent === focus && d.depth === 2 ? "stroke" : null)
                .attr("pointer-events", d => d === root ? "none" : d.depth === 2 ? "none" : null)
                // .attr("pointer-events", d => null)
                // .on("mouseover", function () { d3.select(this).attr("stroke", d => d === root ? null : d.children.length === 1 && focus === root ? null : "#000"); console.log(focus === root)}) // figure out how to get stroke to only show up / pointer event only there for depth 2 if at depth 1
                .on("mouseover", function () {
                    d3.select(this).attr("stroke", d => d === root ? null : "#000")
                })
                .on("mouseout", function () { d3.select(this).attr("stroke", null); })
                .on("click", (event, d) => focus !== d && (zoom(event, d), event.stopPropagation()));

            //highlight in light blue background color 
            const highlight = svg.append("g")
                .attr("pointer-events", "none")
                .attr("text-anchor", "middle")
                .selectAll("rect")
                .data(root.descendants())
                .join("rect")
                // .attr("x", d => d.x)
                // .attr("y", d => d.y)
                // .attr("bbox", d => this ? this.getBBox() : null)
                .style("fill", "#ebf2ff")
                // .style("fill", d => d === root ? "none" : d.depth === 2 ? colors[d.parent.parent.children.indexOf(d.parent) % 3] : "none")                
                // .style("fill-opacity", d => focus === root ? 1 : 0)
                .style("opacity", d => d.depth === 1 && focus === root ? 0.8 : 0)
                .attr("width", d => d.data[0] ? d.data[0].length * 12 : 0)
                .attr("height", 30)

            const label = svg.append("g")
                .attr("class", "bubble-label")
                .attr("pointer-events", "none")
                .attr("text-anchor", "middle")
                // .attr("transform", `translate(0,0)`)
                .selectAll("text")
                .data(root.descendants())
                .join("text")
                // .attr("x", d => d.x)
                // .attr("y", d => d.y)
                // .attr("y", d => d.value > 20 || d.value === 1 ? null : d.value >= 6 ? (d.y - d.r) - g1.offsetHeight / 1.8010 : null)
                // .attr("y", d => d.depth === 1 ? d.y < 0 ? (-d.y + d.r + margin.top) : d.y < 200 ? (-d.y + d.r) / 3 : (-d.y + d.r) / 4 : null)
                // .style("opacity", d => d.children ? 1 : 0)
                // .attr("transform", d => `translate(${d.x},${d.y})`)
                .style("opacity", 1)
                .style("font-size", "20px")
                // .style("fill", d => d === root ? "none" : d.depth === 1 ? colors[d.parent.children.indexOf(d) % 3] : "none")
                // .style("font", function (d) { return d.r > 30 ? "10px sans-serif" : d.r > 8 ? "4.5px sans-serif" : "3px sans-serif" })
                .style("fill-opacity", d => d.parent === root ? 1 : 0)
                .style("display", d => d.parent === root ? "inline" : "none")
                .text(d => d.data[0]);
            // .call(wrap, width);

            // add title
            // svg.append("text")
            //     .attr("x", 0)             
            //     .attr("y", (-height + margin.top) / 2)
            //     .attr("text-anchor", "middle")  
            //     .style("font-size", "16px") 
            //     // .style("text-decoration", "highlight") //highlight in the color of the circles 
            //     .text(titleView ? topic : null);


            zoomTo([root.x, root.y, root.r * 2], [170, 165, 330]);

            function zoomTo(v, view2) {
                const k = width / v[2];

                view = v;
                // console.log('view2 is ', view2)
                // view2 === [170, 165, 330] ? setTitleView(true) : setTitleView(false)
                // console.log('title view: ', titleView)

                label.attr("transform", d => d.r > 8 ? `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`
                    : d.x > 200 ? `translate(${(d.x - v[0] + 15) * k},${(d.y - v[1]) * k})`
                        : `translate(${(d.x - v[0] - 15) * k},${(d.y - v[1]) * k})`);
                highlight.attr("transform", d => d.depth === 1 && d.data[0] ? `translate(${(d.x - v[0]) * k - (d.data[0].length * 6)},${(d.y - v[1]) * k - 23})`
                    : null);
                node.attr("transform", d => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`);
                node.attr("r", d => d.r * k);
            }

            function zoom(event, d) {
                focus = d;
                setCurrText(d)

                const transition = svg.transition()
                    .duration(750)
                    .tween("zoom", d => {
                        // console.log('view jdksldjfk ', view)
                        const i = d3.interpolateZoom(view, [focus.x, focus.y, focus.r * 2]);
                        return t => zoomTo(i(t), view);
                    });

                label
                    .transition(transition)
                    .style("fill-opacity", d => d.parent === focus ? 1 : 0)
                    .on("start", function (d) { if (d.parent === focus) this.style.display = "inline"; })
                    .on("end", function (d) { if (d.parent !== focus) this.style.display = "none"; });

                node
                    .transition(transition)
                    // .selectAll("tspan")
                    .style("opacity", d => d.children === focus ? 0 : 1)

                highlight
                    .transition(transition)
                    .style("opacity", d => root === focus && d.depth === 1 ? 0.8 : 0)

                // console.log(d)

            }
        }
    }, [data])

    return (
        // <div className="stats-container" >
        <div className="stats" id="Intersectionality">

            {/* <h3> {topic} </h3> */}
            {/* <svg width={width}
                    height={height}  >
                        <g className="bubble-chart" />
                    {/* <g className="chart" ref={ref} transform={`translate(${size / 1.2} ${size / 3})`} >
                        <g className="square" />
                    </g> */}
            {/* </svg>  */}
        </div>
    );

};

export default BubbleChart;