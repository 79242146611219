import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Player } from 'video-react';
import { isMobile } from 'react-device-detect';
import '../css/ModulesScreen.css';
import Arrow from '../img/next_arrow.png';
import axios from 'axios';
import { useHistory } from 'react-router';
import Popup from 'reactjs-popup';
import '../../node_modules/video-react/dist/video-react.css'; 
    
const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

// var storage = firebase.storage();
// const storageRef = storage.ref("AMY_EC.mp4");
// let amy = "";
// storageRef.getDownloadURL().then(url => { amy = url })
// console.log(amy);

// look into using firebase storage, best practices
const sources = {
    "Introduction": ['https://firebasestorage.googleapis.com/v0/b/empoweredconversations-9be02.appspot.com/o/SIMRAN_EC.mp4?alt=media&token=ad3593d4-e65b-40db-972d-8fc6322d2653', 'Module_1'],
    "Believe the Survivor": ['https://firebasestorage.googleapis.com/v0/b/empoweredconversations-9be02.appspot.com/o/ISAIAHEC2NEW.mp4?alt=media&token=68157f88-1d1e-4a16-beb5-ae3bb7e0c086', 'Module_2'],
    "Don't Ask Questions": ['https://firebasestorage.googleapis.com/v0/b/empoweredconversations-9be02.appspot.com/o/AMYEC2NEW.mp4?alt=media&token=223a3f19-f3f1-4252-8084-889f40b68114', 'Module_3'],
    "Support the Survivor's Decisions": ['https://firebasestorage.googleapis.com/v0/b/empoweredconversations-9be02.appspot.com/o/MARIANNA_EC.mp4?alt=media&token=d2f6c75a-642c-425d-865a-4ba42fb27fc2', 'Module_4'],
    "Continue the Support": ['https://firebasestorage.googleapis.com/v0/b/empoweredconversations-9be02.appspot.com/o/SOPHIA_EC.mp4?alt=media&token=20a8e888-997c-4090-84b1-963b213b4087', 'Module_5'],
    "Conclusion": ['https://firebasestorage.googleapis.com/v0/b/empoweredconversations-9be02.appspot.com/o/HAILEY_EC.mp4?alt=media&token=92d03bf7-1460-43e2-ba72-c44976b11eb4', 'Module_6']
};

const modules = [
    "Introduction",
    "Believe the Survivor",
    "Don't Ask Questions",
    "Support the Survivor's Decisions",
    "Continue the Support",
    "Conclusion"
];

const ModuleVideo = ({convoId, query, textSent}) => {
    const [active, setActive] = useState('Introduction');
    const [hasEnded, setHasEnded] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [completedVideos, setCompletedVideos] = useState([]);
    const [closed, setClosed] = useState(false);
    const ref = useRef(null);
    let history = useHistory();

    const handleStateChange = (name, index) => {
        if (name) {
            setActive(name)
        } else {
            const nextModule = modules[index + 1];
            setActive(nextModule);
        }

        //new video, reset hasEnded
        setHasEnded(false)
    };

    const handleFinish = () => {
        history.push({
            pathname: '/resources',
            banner: "modules" 
        })
    };

    //Called from Player onEnded property
    const videoEnded = () => {
        setHasEnded(true);
        let tempCompleted = []
        if (!completedVideos.includes(active)) {
            tempCompleted = [...completedVideos, active]
            setCompletedVideos([
                ...completedVideos,
                active
            ])
        } else {
            tempCompleted = [...completedVideos]
        }
        if (active === "Conclusion" && tempCompleted.length > 5 ) {
            setCompleted(true)
        }
    }

    //useCallback is used here to prevent unnecessary rerenders (and get rid of warnings from useEffect)
    const handleTextSend = useCallback(() => {
        if (convoId && !textSent) {
            axios
                .post(`${apiEndpoint}/send-survivor-text`, { convoId, query })
                .then(_ => {
                    console.log('success')
                    return;
                })
                .catch(err => {
                    console.error(err);
                    alert("An unexpected error occurred!");
                    return;
                });    
        }
    }, [convoId, textSent, query]);

    //Called whenever completedVideos changes
    //Makes sure all videos are complete before sending text
    useEffect(() => {
        if (completedVideos.length === 6) {
            handleTextSend()
        }
    }, [completedVideos, handleTextSend]);

    return (
        <div className="clearfix">
            {/* Adding a banner to mobile phones  */}
            {/* {isMobile ? 
                <div className="proto-banner">
                    <div>
                        <button className="close" style={{color: 'white'}} onClick={() => setClosed(true)}>
                            &times;
                        </button>
                    </div>
                    We are currently working on a mobile application for our tool. In the meantime, our module videos are best viewed on a laptop or desktop device.  
                </div>
            : null} */}
            <div className="side-nav">
                 {modules.map(function (name, index) {
                    return <input
                        type="button"
                        className={active === name ? 'module-active' : 'module'}
                        value={name}
                        onClick={() => handleStateChange(name)}
                        key={ name } />;
                })}
            </div>
            <div className="module-video">
                <Player
                    ref={ref}
                    poster={require(`../img/${sources[active][1]}.jpg`)}
                    // src={require(`../modules/${sources[active][0]}.mp4`)}
                    src={sources[active][0]}

                    //onEnded, onSeeking are undocumented. Found from https://github.com/video-react/video-react/blob/5ba018d095c9a00572d91ecbf853450a150f480f/src/components/Player.js#L44
                    //Removes need to constantly rerender to check if video is ended
                    onEnded ={() => videoEnded()}
                    onSeeking = {() => setHasEnded(false)}
                />
                {hasEnded && (
                    active !== "Conclusion" 
                    ? <div className="button-2-container">
                        <button className="button-2" 
                        onClick={() => { 
                                    handleStateChange('', modules.indexOf(active))
                                }}
                        > Next <img src={Arrow} alt='' width="50px" height="50px" /> </button> 
                    </div>
                    : (convoId === '' 
                        ? <div className="button-2-container">
                            <button className="button-2" onClick={handleFinish}> Exit to Resources <img src={Arrow} alt='' width="50px" height="50px" /> </button>
                        </div> 
                        : (completed === true 
                            ? <div className="button-2-container">
                                <button className="button-2" onClick={handleFinish}> Exit to Resources <img src={Arrow} alt='' width="50px" height="50px" /> </button>
                            </div> 
                            : <Popup 
                                defaultOpen
                                position="center center"
                                modal
                                // lockScroll
                                closeOnDocumentClick={false}
                                closeOnEscape={false}
                            >
                                {close => (<div className="code-container">
                                    <button className="close" onClick={close}>
                                        &times;
                                    </button>
                                    <div className="header"> 
                                        It appears that you have not completed all of the videos. In order to complete the training modules and best prepare for the conversation, please watch the following module(s) until the "Next" button appears:
                                    </div>
                                    {modules.map(video => {
                                        if (!completedVideos.includes(video) && video !== "Conclusion") {
                                            return(<p key={video}>{video}</p>)
                                        } else {
                                            return null;
                                        }
                                    })}
                                    </div>)}
                            </Popup>))
                )}
            </div>
        </div>
    )
}

export default ModuleVideo;